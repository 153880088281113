declare global {
    interface Window {
        firebase: any;
    }
}

const FirebaseConfig: {
    apiKey: string
    authDomain: string
    databaseURL: string
    projectId: string
    storageBucket: string
    messagingSenderId: string,
} = {
    apiKey: 'AIzaSyCWckKOcI-E-q-EWrko4jg1qvwznY1nxVk',
    authDomain: 'smileonthetiles-fda23.firebaseapp.com',
    databaseURL: 'https://smileonthetiles-fda23.firebaseio.com',
    projectId: 'smileonthetiles-fda23',
    storageBucket: 'smileonthetiles-fda23.appspot.com',
    messagingSenderId: '91218899089',
};

export default FirebaseConfig;
