<template>
  <div class="album-access-management white-background-colour">
    <div class="container cosy">
      <div class="column full">
        <table>
          <thead>
            <tr>
              <th width="10%" class="hide-on-mobile">When</th>
              <th width="20%">Name</th>
              <th width="10%">Access</th>
              <th width="35%">Message</th>
              <th width="25%">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="hide-on-mobile">-</td>
              <th>{{ $store.state.user.displayName || $store.state.user.email }} (you!)</th>
              <td>
                <i class="material-icons">assignment_ind</i>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr v-if="loading">
              <td colspan="5" class="align-center">Loading...</td>
            </tr>
            <template v-else>
              <tr v-for="user in users">
                <td class="hide-on-mobile">
                  {{ PrettyAge( user.date_requested_timestamp ) }}
                </td>
                <th>
                  {{ user.name }}
                </th>
                <td>
                  <i v-if="user.approved === true" class="material-icons">visibility</i>
                  <i v-else class="material-icons">visibility_off</i>
                </td>
                <td>
                  {{ user.message }}
                </td>
                <td>
                  <div class="flexbox width-full">
                    <template v-if="user.loading">
                      <button class="flex-1 block tiny inverted" disabled>Loading...</button>
                    </template>
                    <template v-else-if="user.approved === true">
                        <button class="flex-1 block tiny ui-good" disabled>Allow</button>
                        <button v-on:click="declineAccess( user.id )" class="flex-1 block tiny ui-bad">Revoke</button>
                    </template>
                    <template v-else-if="user.approved === false">
                        <button v-on:click="approveAccess( user.id )" class="flex-1 block tiny ui-good">Allow</button>
                        <button class="flex-1 block tiny ui-bad" disabled>Revoke</button>
                    </template>
                    <template v-else>
                        <button v-on:click="approveAccess( user.id )" class="flex-1 block tiny ui-good">Approve</button>
                        <button v-on:click="declineAccess( user.id )" class="flex-1 block tiny ui-bad">Deny</button>
                    </template>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import FetchJSON from '../../libs/FetchJSON'
  import FetchPOST from '../../libs/FetchPOST'
  import PrettyAge from '../../libs/PrettyAge'

  export default {
    name: 'album-access-management',
    props: ['albumID'],
    data() {
      return {
        loading: true,
        users: []
      }
    },
    computed: {
      apiURLs() {
        return {
          approve: this.$root.apiURL + '/access/' + this.albumID + '/approve',
          decline: this.$root.apiURL + '/access/' + this.albumID + '/decline'
        }
      }
    },
    methods: {
      approveAccess( accessID ) {
        const user = this.users.find( user => user.id === accessID )
        if( user ) {
          user.loading = true
          FetchPOST( this.apiURLs.approve, { key: user.key } )
            .then( response => {
              if( response.success ) {
                user.approved = true
              } else {
                console.error( response.error )
              }
              user.loading = false
            } )
            .catch( response => {
              user.loading = false
              console.error( response )
            } )
        } else {
          console.error( 'User access ID not found' )
        }
      },
      declineAccess( accessID ) {
        const user = this.users.find( user => user.id === accessID )
        if( user ) {
          user.loading = true
          FetchPOST( this.apiURLs.decline, { key: user.key } )
            .then( response => {
              if( response.success ) {
                user.approved = false
              } else {
                console.error( response.error )
              }
              user.loading = false
            } )
            .catch( response => {
              user.loading = false
              console.error( response )
            } )
        } else {
          console.error( 'User access ID not found' )
        }
      },
      getUsersWaitingForAccess() {
        FetchJSON( this.$root.apiURL + '/access/' + this.albumID + '/everyone' )
          .then( response => {
            if( response.data ) {
              this.users = response.data.map( user => {
                user.loading = false;
                return user;
              } )
            } else {
              console.error( 'Missing data' )
            }
            this.loading = false
          } )
          .catch( response => {
            console.error( response )
            this.loading = false
          } )
      },
      PrettyAge
    },
    mounted() {
      this.getUsersWaitingForAccess();
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../styles/env";

  .album-access-management {
    max-height: 50rem;
    padding-top: 4rem;

    overflow: auto;

    background-color: #FFF;

    transition: padding-top 200ms;

    @media (max-width: #{$largest-width}) {
      padding-top: 1rem;
    }
  }
</style>
