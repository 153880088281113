<template>
  <div id="app" v-on:click="bodyClick" :data-route="$route.name" class="flexbox-vertical" :class="{standalone: $root.standalone}">
    <meta name="version" :content="version"/>
    <navigation-bar v-if="!$root.standalone" ref="navbar"/>
    <router-view :key="JSON.stringify( Object.assign( {}, $route.params ) ) + $route.query.page + $route.query.q"/>
    <navigation-bar-bottom v-if="$root.standalone"/>
    <system-notices/>
    <notification-chips/>
  </div>
</template>

<script>
  import NavigationBar from './components/NavigationBar.vue'
  import SystemNotices from './components/SystemNotices.vue'
  import NotificationChips from './components/NotificationChips.vue'
  import { version } from '../package.json'
  import NavigationBarBottom from './components/NavigationBarBottom'

  export default {
    name: 'app',
    components: {
      NavigationBarBottom,
      NotificationChips,
      NavigationBar,
      SystemNotices
    },
    data() {
      return {
        version: version
      }
    },
    methods: {
      bodyClick() {
        this.closeSidebar()
        this.closeDropdown()
      },
      closeSidebar() {
        if( this.$refs.navbar ) {
          this.$refs.navbar.closeSidebar()
        }
      },
      closeDropdown() {
        if( this.$refs.navbar ) {
          this.$refs.navbar.closeDropdown()
        }
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss">
  @import "./styles/global";

  #app {
    min-height: 100vh;
  }
</style>
