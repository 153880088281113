import Vue from 'vue';
import Vuex, {StoreOptions} from 'vuex';
import Store from '@/interfaces/Store';
// import FirebaseMessaging from '@/firebase/Messaging';
import FetchPOST from '@/libs/FetchPOST';
import Config from '@/Config';

Vue.use(Vuex);

let localVuexStore: { [key: string]: any } | null = null;

if (window.localStorage.getItem('sottVuexStore')) {
    const stored = window.localStorage.getItem('sottVuexStore');
    if (stored) {
        try {
            localVuexStore = JSON.parse(stored) || null;
        } catch (e) {
            //
        }
    }
}

const writeFavouritesToDatabase = (favourites: Array<number|string> = []) => {
    window.firebase.database()
        .ref('users/' + window.firebase.auth().currentUser.uid)
        .set({
            favourites,
        });
};

let notificationCount = 0;

const state: Store = {
    persistent: {
        listView: false,
        redirectAfterLogin: '',
    },
    favourites: [],
    subscribedTopics: [],
    notifications: [],
    user: {
        loggedIn: false,
        uid: '',
        displayName: '',
        email: '',
        photoURL: '',
        jwt: '',
    },
};

const options: StoreOptions<Store> = {
    state,
    mutations: {
        initialiseStore(currentState: Store) {
            const newState = Object.assign({}, currentState);

            if (localVuexStore) {
                newState.persistent = Object.assign({}, currentState.persistent, localVuexStore);
                this.replaceState( Object.assign({}, currentState, newState ), null );
            }

            // if ( FirebaseMessaging.permissionGranted() ) {
            //     Store.commit('setUpSubscriptions');
            // }
        },
        redirectAfterLogin(currentState: Store, uri = '/') {
            if (uri === '/sign-in') {
                uri = '/';
            }
            currentState.persistent.redirectAfterLogin = uri;
        },
        loggedIn(currentState: Store, payload) {
            currentState.user.loggedIn = true;
            currentState.user.uid = payload.uid || '';
            currentState.user.displayName = payload.displayName || '';
            currentState.user.email = payload.email || '';
            currentState.user.photoURL = payload.photoURL || '';
            currentState.user.jwt = payload.jwt || '';
            /* global firebase */
            window.firebase.database()
                .ref('/users/' + window.firebase.auth().currentUser.uid)
                .once('value')
                .then((snapshot: any) => {
                    if (snapshot.val()) {
                        Store.commit('setUpFavourites', snapshot.val().favourites);
                    } else {
                        Store.commit('setUpFavourites');
                        writeFavouritesToDatabase([]);
                    }
                });
        },
        loggedOut(currentState: Store) {
            currentState.user.loggedIn = false;
            currentState.user.uid = '';
            currentState.user.displayName = '';
            currentState.user.email = '';
            currentState.user.photoURL = '';
            currentState.user.jwt = '';
            currentState.favourites = [];
        },
        setUpFavourites(currentState: Store, favourites: Array<number|string> = []) {
            currentState.favourites = favourites.reverse();
        },
        favourite(currentState: Store, id: number | string) {
            id = parseInt(id.toString(), 10);
            if (currentState.favourites.indexOf(id) === -1) {
                currentState.favourites.push(id);
                writeFavouritesToDatabase(currentState.favourites);
            }
        },
        unfavourite(currentState: Store, id: number | string) {
            id = parseInt(id.toString(), 10);
            const index = currentState.favourites.indexOf(id);
            if (index > -1) {
                currentState.favourites.splice(index, 1);
                writeFavouritesToDatabase(currentState.favourites);
            }
        },
        setUpSubscriptions(currentState: Store) {
            // FirebaseMessaging.getToken()
            //     .then((token: string) => {
            //         FetchPOST(Config.apiURL + '/topics/subscribed', {token})
            //             .then((response: any) => {
            //                 if (response.data) {
            //                     currentState.subscribedTopics = response.data;
            //                 }
            //             });
            //     });
        },
        addTopicSubscription(currentState: Store, topic: string) {
            currentState.subscribedTopics.push( topic );
        },
        removeTopicSubscription(currentState: Store, subscribedTopic: string) {
            const topicIndex = currentState.subscribedTopics.findIndex((topic) => topic === subscribedTopic);
            if (topicIndex > -1) {
                currentState.subscribedTopics.splice(topicIndex, 1);
            }
        },
        notify(currentState: Store, notification: { [key: string]: any }) {
            notification.id = notificationCount++;
            currentState.notifications.push(notification);
        },
        removeNotification(currentState: Store, id: number | string) {
            const notificationIndex = currentState.notifications.findIndex((notification) => notification.id === id);
            if (notificationIndex > -1) {
                currentState.notifications.splice(notificationIndex, 1);
            }
        },
        switchListView(currentState: Store, value: boolean) {
            currentState.persistent.listView = value;
        },
    },
};

const Store: any = new Vuex.Store(options);

Store.subscribe((mutation: any, currentState: any) => {
    const saveState = Object.assign({}, currentState.persistent);
    window.localStorage.setItem('sottVuexStore', JSON.stringify(saveState));
});

export default Store;
