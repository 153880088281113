<template>
  <figure class="gallery-item-photo" itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject" :data-databaseid="id" :data-index="index" :class="classes">
    <a :href="this.photo.share_src" v-on:click.prevent="openGallery" itemprop="contentUrl" :style="{ paddingBottom: aspect }" :class="{ loaded: fullLoaded }">
      <img v-show="fullLoaded" :src="photo.msrc" alt="Smileonthetiles photo"> </a>
    <meta itemprop="width" :content="photo.w">
    <meta itemprop="height" :content="photo.h">
    <span class="hidden" itemprop="copyrightHolder">Smileonthetiles</span>
  </figure>
</template>

<script>
  export default {
    name: 'gallery-item-photo',
    props: ['index', 'photo', 'offset', 'animateIn'],
    data() {
      return {
        id: this.photo.db_id,
        // full: this.photo.src,
        // thumb: this.photo.msrc,
        // mini: this.photo.mini,
        // shareURL: this.photo.share_src,
        fullLoaded: false,
        aspect: ( ( this.photo.h / this.photo.w ) * 100 ) + '%'
      }
    },
    computed: {
      classes() {
        let classes = {}
        if( this.animateIn === true ) {
          classes['animate-zoom-in'] = true
          classes['animation-offset-' + this.offset] = true
        }
        return classes
      }
    },
    mounted() {
      /* global Image */
      let img = new Image()
      img.src = this.photo.msrc
      img.onload = () => {
        this.fullLoaded = true
      }
    },
    methods: {
      openGallery() {
        this.$emit( 'openGallery', this.index )
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../styles/env";

  .gallery-item-photo {
    width: 100%;

    @for $i from 1 through 12 {
      &.animation-offset-#{$i} {
        a {
          &:before {
            animation-delay: ($i - 1) * 100ms;
          }
        }
      }
    }
  }

  a {
    height: 0;

    display: block;

    position: relative;

    &:before {
      display: block;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      background: linear-gradient(-45deg, #EEE, #BCBCBC, #EEE);
      background-size: 400% 400%;

      animation: slide-background ease 1.2s infinite;

      content: "";
    }

    &.loaded {
      height: auto;
      padding-bottom: 0 !important;

      &:before {
        display: none;
      }
    }
  }

  img {
    width: 100%;
  }
</style>
