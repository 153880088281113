<template>
  <div class="locked-album container flexbox-justify-center">
    <div class="column width-2-3 width-4-5-medium width-full-mobile">
      <div class="locked-notice">
        <div class="card list label-red" data-label="Locked">
          <img :src="album.image.url" class="card-image">
          <div class="flex-1 card-content">
            <h3 v-text="album.title" class="card-title"/>
            <h4 v-text="album.parent.title"/>
            <p v-if="album.access.requested">You have requested access to this album</p>
            <p v-else>This album is locked</p>
          </div>
        </div>
      </div>
      <p v-if="success" class="success">Thank you - Your request has been sent</p>
      <form v-else-if="!album.access.requested" v-on:submit.prevent="onSubmit" class="form">
        <div v-show="sending" class="loader-mask">
          <div class="loader"/>
        </div>
        <h3>Request access to this album</h3>
        <p><strong>Please note:</strong> You can send a request to access an album only once.</p>
        <label class="flexbox flexbox-align-end">
          <span>Your name</span>
          <div class="flex-1">&nbsp;</div>
          <small>(so the admin knows who you are)</small>
        </label>
        <input v-model="data.name" :readonly="!!$store.state.user.displayName" type="text" name="name" maxlength="255" placeholder="John Smith" autocomplete="name" required/>
        <label>Message to album admin</label>
        <textarea v-model="data.message" name="message" placeholder="Let the admin know who you are..." rows="5"></textarea>
        <p v-if="captchaMissing" class="info">Please prove you are not a robot 🤖</p>
        <p v-if="errorMessage" v-html="errorMessage" class="error"></p>
        <div class="flexbox flexbox-align-end flexbox-space-between flexbox-wrap">
          <div>
            <div id="g-recaptcha"></div>
          </div>
          <button type="submit" class="block">
            <i class="material-icons inline hide-on-tiny">lock_open</i>
            Request access
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import FetchPOST from '../../libs/FetchPOST'

  export default {
    name: 'locked-album',
    props: ['album'],
    data() {
      return {
        captcha: null,
        captchaMissing: false,
        sending: false,
        success: false,
        errorMessage: null,
        data: {
          name: this.$store.state.user.displayName || '',
          message: '',
          verification: ''
        }
      }
    },
    computed: {
      apiRequestURL() {
        return this.$root.apiURL + '/access/' + this.album.id + '/request'
      }
    },
    methods: {
      onSubmit() {
        this.captchaMissing = false
        this.errorMessage = null
        /* global grecaptcha */
        this.data.verification = grecaptcha.getResponse( this.captcha )
        if( this.data.verification ) {
          this.send()
        } else {
          this.captchaMissing = true
        }
      },
      send() {
        this.sending = true
        FetchPOST( this.apiRequestURL, this.data )
          .then( response => {
            if( response.success === true ) {
              this.success = true;
            } else {
              this.success = false;
              grecaptcha.reset()
              if( response.error ) {
                this.errorMessage = response.error
              }
            }
            this.sending = false
          } )
          .catch( e => {
            console.error( e )
            this.success = false;
            this.errorMessage = e.message || 'An error occurred'
            this.sending = false
            this.captchaMissing = false
            grecaptcha.reset()
          } )
      }
    },
    mounted() {
      /* global grecaptcha */
      this.captcha = grecaptcha.render( 'g-recaptcha', {
        sitekey: '6LfadFcUAAAAAJFas2B5UKEVIHrY1iSHUILaLi4p'
        // theme: 'dark',
        // size: 'compact'
      } )
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../styles/env";

  .locked-album {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .locked-notice {
    overflow: hidden;

    border-radius: $border-radius;

    @include shadow-2;

    .card {
      margin-bottom: 0;

      align-items: center;

      border-radius: 0;

      z-index: 1;

      .card-content {
        padding: 2rem;

        /*text-align: right;*/

        align-self: stretch;

        .card-title {
          /*text-align: left;*/
        }

        .block {
          margin: 0;

          align-self: flex-end;
        }
      }
    }
  }

  .form,
  .success {
    margin-top: 2rem;
  }
</style>
