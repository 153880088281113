import PrettyDate from './PrettyDate';

const fltYearDays = 356.2425;

export default function PrettyAge(unixTime: number) {
    let intSecondsDifference = parseInt(PrettyDate('U'), 10) - unixTime;
    const blFuture = intSecondsDifference < 0;
    const intMonthSeconds = (fltYearDays / 12) * 86400;
    intSecondsDifference = Math.abs(intSecondsDifference);
    let strOut = '';
    if (intSecondsDifference < 60) {
        return blFuture ? 'In a moment' : 'A moment ago';
    } else if (intSecondsDifference < 120) {
        return blFuture ? 'In an minute' : 'A minute ago';
    } else if (intSecondsDifference < 3600) {
        strOut = Math.floor(intSecondsDifference / 60) + ' minutes';
    } else if (intSecondsDifference < 7200) {
        return blFuture ? 'In an hour' : 'An hour ago';
    } else if (intSecondsDifference < 86400) {
        strOut = Math.floor(intSecondsDifference / 3600) + ' hours';
    } else if (intSecondsDifference < 172800) {
        return blFuture ? 'Tomorrow' : 'Yesterday';
    } else if (intSecondsDifference < intMonthSeconds) {
        strOut = Math.floor(intSecondsDifference / 86400) + ' days';
    } else if (intSecondsDifference < intMonthSeconds * 2) {
        return blFuture ? 'In a month' : 'A month ago';
    } else if (intSecondsDifference < fltYearDays * 86400) {
        strOut = Math.floor(intSecondsDifference / intMonthSeconds) + ' months';
    } else if (intSecondsDifference < fltYearDays * 86400 * 2) {
        return blFuture ? 'In a year' : 'A year ago';
    } else {
        strOut =
            Math.floor(intSecondsDifference / (fltYearDays * 86400)) + ' years';
    }
    return blFuture ? 'In ' + strOut : strOut + ' ago';
}
