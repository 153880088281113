<template>
  <nav class="pagination flexbox flexbox-align-stretch">
    <button :disabled="currentPage <= 1" v-on:click.prevent="navigateTo( 1 )" class="flex-1">
      <i class="material-icons inline">first_page</i><span class="hide-on-mobile"> First</span></button>
    <button :disabled="currentPage <= 1" v-on:click.prevent="navigateTo( currentPage - 1 )" class="flex-1">
      <i class="material-icons inline">chevron_left</i><span class="hide-on-mobile"> Previous</span></button>
    <button v-for="i in pageListPadding" v-on:click.prevent="navigateTo( currentPage - ( pageListPadding - i + 1 ) )" v-if="parseInt( currentPage ) - ( pageListPadding - i + 1 ) > 0" class="flex-1">{{ ( parseInt( currentPage ) - ( pageListPadding - i + 1 ) ).toLocaleString() }}
    </button>
    <button class="flex-1 current">{{ currentPage.toLocaleString() }}</button>
    <button v-for="i in pageListPadding" v-on:click.prevent="navigateTo( currentPage + i )" v-if="parseInt( currentPage ) + i <= pages" class="flex-1">{{ ( parseInt( currentPage ) + i ).toLocaleString() }}
    </button>
    <button :disabled="currentPage >= pages" v-on:click.prevent="navigateTo( currentPage + 1 )" class="flex-1">
      <span class="hide-on-mobile">Next </span><i class="material-icons inline">chevron_right</i>
    </button>
    <button :disabled="currentPage >= pages" v-on:click.prevent="navigateTo( pages )" class="flex-1">
      <span class="hide-on-mobile">Last </span><i class="material-icons inline">last_page</i></button>
  </nav>
</template>

<script>
  export default {
    name: 'pagination',
    props: ['currentPage', 'pages', 'padding'],
    computed: {
      pageListPadding() {
        return this.padding || 5
      }
    },
    methods: {
      navigateTo: function( i ) {
        this.$emit( 'navigateTo', i )
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";

  .pagination {
    button {
      padding: 1rem 0;

      white-space: nowrap;

      background-color: transparent;

      &:not(:disabled):hover {
        color: $primary-colour;
      }

      &.current {
        color: #FFF;
        font-weight: 700;

        background-color: $primary-colour;

        &:hover {
          color: #FFF;
        }
      }

      &:disabled {
        opacity: 0.35;
      }

      span {
        vertical-align: middle;
      }
    }
  }
</style>
