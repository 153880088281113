import { render, staticRenderFns } from "./NavigationBarBottom.vue?vue&type=template&id=1177aa50&scoped=true&"
import script from "./NavigationBarBottom.vue?vue&type=script&lang=js&"
export * from "./NavigationBarBottom.vue?vue&type=script&lang=js&"
import style0 from "./NavigationBarBottom.vue?vue&type=style&index=0&id=1177aa50&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1177aa50",
  null
  
)

export default component.exports