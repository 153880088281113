<template>
  <!--<div class="search-bar container">-->
  <!--<div class="column full">-->
  <div class="search-form flexbox flexbox-align-stretch">
    <label for="searchterm" class="material-icons">search</label>
    <input id="searchterm" v-model="searchString" name="q" v-on:keyup.13="search" type="search" :placeholder="placeholder || 'Search...'" class="flex-1"/>
    <button v-on:click.prevent="search" :disabled="!searchString">
      <i class="material-icons">arrow_forward</i>
    </button>
  </div>
  <!--</div>-->
  <!--</div>-->
</template>

<script>
  export default {
    name: 'search-bar',
    props: ['placeholder'],
    data() {
      return {
        searchString: this.$route.query.q
      }
    },
    methods: {
      search() {
        this.$router.push( { name: 'search', query: { q: this.searchString || this.placeholder || '' } } )
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";

  .search-bar {
    padding-top: ($gutter / 2);
    padding-bottom: ($gutter / 2);
  }

  .search-form {
    height: 6rem;

    overflow: hidden;

    color: #FFF;
    line-height: 6rem;

    background-color: rgba(255, 255, 255, 0.15);

    border-radius: $border-radius;

    @include shadow-2;

    .material-icons {
      padding: 0 2rem;

      line-height: inherit;
    }

    label {
      color: rgba(255, 255, 255, 0.75);
    }

    input,
    button {
      display: block;

      background-color: transparent;

      border: none;
    }

    input {
      font-size: 2.5rem;

      border-radius: 0;

      &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.25);
      }

      &::-moz-placeholder {
        color: rgba(255, 255, 255, 0.25);
      }

      &:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.25);
      }

      &:-moz-placeholder {
        color: rgba(255, 255, 255, 0.25);
      }
    }

    button {
      padding: 0;

      color: inherit;
      font-size: 2.5rem;

      opacity: 0.75;

      &:hover {
        opacity: 1;
      }
    }
  }
</style>
