import Home from '../views/Home.vue';
import Gallery from '../views/Gallery.vue';
import Search from '../views/Search.vue';
// import About from '../views/About.vue';
// import AboutHome from '../views/about/AboutHome.vue';
// import AboutNews from '../views/about/AboutNews.vue';
// import AboutJobs from '../views/about/AboutJobs.vue';
// import Contact from '../views/Contact.vue';
// import ContactHome from '../views/contact/ContactHome.vue';
// import ContactFaqs from '../views/contact/ContactFaqs.vue';
import MySmiles from '../views/MySmiles.vue';
import SignIn from '../views/SignIn.vue';
import SignOut from '../views/SignOut.vue';
// import Terms from '../views/Terms.vue';
// import Privacy from '../views/Privacy.vue';
import PageNotFound from '../views/PageNotFound.vue';
import {RouteConfig} from 'vue-router';

const Routes: RouteConfig[] = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            description: 'Smileonthetiles provides free professional photos of you out on the tiles',
        },
    },
    {
        path: '/galleries/:galleryURI*',
        name: 'galleries',
        component: Gallery,
        // meta: {
        //     title: 'Galleries',
        // },
    },
    {
        path: '/search',
        name: 'search',
        component: Search,
        meta: {
            title: 'Search',
        },
    },
    {
        path: '/about',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "about" */ '../views/about/AboutHome.vue'),
                meta: {
                    title: 'About us',
                    description: 'The history of Smileonthetiles',
                },
            },
            {
                path: 'alex',
                component: () => import(/* webpackChunkName: "about" */ '../views/about/AboutAlex.vue'),
                meta: {
                    title: 'About Alex',
                    description: 'Alex Christaki, creator of Smileonthetiles',
                },
            },
            // {
            //     path: 'newsletter',
            //     component: () => import(/* webpackChunkName: "about" */ '../views/about/AboutNewsletter.vue'),
            //     meta: {
            //         title: 'Newsletter',
            //         description: 'Sign up for the Smileonthetiles newsletter',
            //     },
            // },
            // {
            //     path: 'news',
            //     component: () => import(/* webpackChunkName: "about" */ '../views/about/AboutNews.vue'),
            //     meta: {
            //         title: 'News',
            //         description: 'News and events',
            //     },
            // },
            // {
            //     path: 'jobs',
            //     component: () => import(/* webpackChunkName: "about" */ '../views/about/AboutJobs.vue'),
            //     meta: {
            //         title: 'Apply for a job',
            //         description: 'Apply for a job with Smileonthetiles',
            //     },
            // },
        ],
    },
    {
        path: '/contact',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "contact" */ '../views/contact/ContactHome.vue'),
                meta: {
                    title: 'Contact us',
                    description: 'Send us a message',
                },
            },
            {
                path: 'faqs',
                component: () => import(/* webpackChunkName: "contact" */ '../views/contact/ContactFaqs.vue'),
                meta: {
                    title: 'FAQs',
                    description: 'Frequently asked questions',
                },
            },
        ],
    },
    {
        path: '/my-smiles',
        name: 'mySmiles',
        component: MySmiles,
        meta: {
            title: 'My Smiles',
            requiresAuth: true,
        },
    },
    {
        path: '/sign-in',
        name: 'signIn',
        component: SignIn,
        meta: {
            title: 'Sign in',
        },
    },
    {
        path: '/sign-out',
        name: 'signOut',
        component: SignOut,
        meta: {
            title: 'Signing out',
        },
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import(/* webpackChunkName: "supporting" */ '../views/Terms.vue'),
        meta: {
            title: 'Terms and conditions',
        },
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import(/* webpackChunkName: "supporting" */ '../views/Privacy.vue'),
        meta: {
            title: 'Privacy policy',
        },
    },
    {
        path: '*',
        component: PageNotFound,
        meta: {
            title: 'Page not found',
        },
    },
];

export default Routes;
