<template>
  <div class="wrapper flexbox flexbox-align-center flexbox-justify-center">
    <div class="report">
      <button v-on:click.prevent="hide" class="hide">
        <i class="material-icons">close</i>
      </button>
      <form v-on:submit.prevent="send" class="form">
        <div v-show="sending" class="loader-mask">
          <div class="loader"/>
        </div>
        <h3>Report photo</h3>
        <p v-if="success" class="success">Thank you - your report has been submitted</p>
        <p v-else-if="error" v-html="errorMessage" class="error"></p>
        <img :src="photo.msrc" alt="Photo to report">
        <template v-if="!success">
          <template v-if="!$store.state.user.email">
            <label>Your email address</label>
            <input v-model="data.email" type="email" name="email" maxlength="255" placeholder="Email address" autocomplete="email" required/>
          </template>
          <label>Reason for removal</label>
          <textarea v-model="data.message" v-on:keydown.stop name="message" placeholder="Please explain why you would like this photo to be removed..." rows="5" required></textarea>
        </template>
        <div class="flexbox flexbox-justify-end">
          <button v-if="!success" type="submit" class="block">Submit</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import FetchPOST from '../../libs/FetchPOST'

  export default {
    name: 'gallery-item-photo-report',
    props: ['photo'],
    data() {
      return {
        error: false,
        errorMessage: null,
        success: false,
        sending: false,
        data: {
          email: this.$store.state.user.email || '',
          message: ''
        }
      }
    },
    mounted() {
    },
    methods: {
      send() {
        this.success = false
        this.sending = true
        this.error = false
        this.errorMessage = null

        FetchPOST( this.$root.apiURL + '/photo/' + this.photo.db_id + '/report', this.data )
          .then( json => {
            // console.log( json )
            if( json.error ) {
              this.error = true
              this.errorMessage = json.error || 'An error occurred'
            } else {
              this.onSuccess()
            }
            this.sending = false
          } )
          .catch( e => {
            console.error( e )
            this.error = true
            this.errorMessage = e.message || 'An error occurred'
            this.sending = false
          } )
      },
      onSuccess() {
        this.success = true
        this.data.message = ''
        setTimeout( this.hide, 2000 )
      },
      hide() {
        this.$emit( 'hide' )
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../styles/env";

  .wrapper {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 100%;
    left: 0;

    //overflow: hidden;

    /*background-color: rgba(0, 0, 0, 0.85);*/

    opacity: 0;

    transition: top 1ms 400ms, opacity $transition-hover-out, transform $transition-hover-out;

    transform: scale(0.5);

    &.show {
      top: 0;

      opacity: 1;

      transition: top 1ms, opacity $transition-hover-out 1ms, transform $transition-hover-out 1ms;

      transform: scale(1);
    }
  }

  .report {
    max-width: calc(100% - 5rem);
    width: 500px;
    max-height: 100%;

    position: relative;

    overflow-x: hidden;
    overflow-y: auto;

    box-sizing: border-box;

    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.85);
  }

  .hide {
    width: 4rem;
    height: 4rem;

    position: absolute;
    top: -1rem;
    right: -1rem;
    z-index: 2;

    color: #FFF;

    background: $primary-colour;

    border-radius: 100%;
  }
</style>
