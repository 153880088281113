<template>
  <div class="photo" :class="{ loaded: loaded }" :style="styles" :data-title="title" :data-date="date">
    <img :src="photo.image.thumb.url" alt="">
  </div>
</template>

<script>
  import PrettyDate from '../libs/PrettyDate'

  export default {
    name: 'home-page-photo',
    props: ['index', 'photo', 'photos'],
    data() {
      return {
        loaded: false,
        titles: [
          'What a great night!',
          'Me with my mates',
          'On the tiles',
          'One to remember',
          'Good times',
          'Woo hoo!',
          'Late night',
          'Best night ever',
          'Party time',
          'Well behaved ;)',
          'Tidy for a Friday!',
          'Funday!',
          'Geddon',
          '<3',
          'Brilliant',
          'Together',
          'The gang',
          ':D',
          'Smiles',
          'Out in town',
          'Night out'
        ],
        titleIndex: Math.random(),
        rotation: ( ( Math.random() * 40 ) - 20 ),
        scale: ( ( Math.random() / 4 ) + 0.75 ),
        zIndex: Math.ceil( Math.random() * this.photos ),
        translateX: this.index % 2 === 0 ? '-50%' : '25%',
        translateZ: ( Math.random() * 200 ) - 100,
        date: PrettyDate( 'jS F', this.photo.meta.timestamp )
      }
    },
    computed: {
      styles() {
        return this.loaded ? {
          top: ( ( ( ( ( Math.floor( this.index / 2 ) * 2 ) / ( this.photos - 2 ) ) + ( 1 / this.photos ) ) * 100 ) ) + '%',
          zIndex: this.zIndex,
          // transform: 'translateY(-50%) rotate(' + this.rotation + 'deg) scale(' + this.scale + ') translateX(' + this.translateX + ')'
          transform: 'scale(1) translate3d(' + this.translateX + ', -50%, ' + this.translateZ + 'px) rotate(' + this.rotation + 'deg)'
        } : {
          top: ( ( this.index / this.photos ) * 100 ) + '%',
          zIndex: this.zIndex
        }
      },
      title() {
        return this.photo.meta.album.venue.title || ''
        // return this.titles[Math.floor( this.titleIndex * this.titles.length )]
      }
    },
    mounted() {
      /* global Image */
      let img = new Image()
      img.src = this.photo.image.thumb.url || this.photo.image.url
      img.onload = () => {
        this.loaded = true
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";

  .photo {
    width: 20vw;
    max-width: 300px;

    position: absolute;
    right: -100%;

    border: 1rem #FFF solid;
    border-bottom-width: 4rem;
    border-radius: $border-radius;

    @include shadow-4;

    // transform: translateY(-50%) rotate(0) scale(0) translateX(150%);
    transform: scale(0) translate3d(150%, -50%, 0) rotate(0);

    transition: transform 400ms;

    &::before,
    &::after {
      position: absolute;
      top: 100%;

      overflow: hidden;

      color: rgba(0, 0, 0, 0.75);
      line-height: 4rem;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &::before {
      right: 40%;
      left: 0;

      font-size: 1.5rem;
      text-align: left;

      content: attr(data-title);
    }

    &::after {
      right: 0;
      left: 60%;

      font-size: 1.25rem;
      text-align: right;

      content: attr(data-date);
    }

    &.loaded {
      right: 0;
    }

    img {
      width: 100%;
    }
  }
</style>
