<template>
  <div class="notifications" :class="{show: $store.state.notifications.length}">
    <notification-chip v-for="notification in $store.state.notifications" :key="notification.id" :notification="notification"></notification-chip>
  </div>
</template>

<script>
  import NotificationChip from './NotificationChip.vue'

  export default {
    name: 'notification-chips',
    components: { NotificationChip },
    mounted() {
      // this.$store.commit( 'notify', {
      //   text: 'Lorem ipsum'
      // } )
      // this.$store.commit( 'notify', {
      //   text: 'Close on click',
      //   style: 'error',
      //   actions: {
      //     'Okay': true
      //   }
      // } )
      // this.$store.commit( 'notify', {
      //   text: 'Navigate on click',
      //   actions: {
      //     'Go': '/galleries'
      //   }
      // } )
      // this.$store.commit( 'notify', {
      //   text: 'Function on click',
      //   lifespan: 5000,
      //   actions: {
      //     'Do': () => {
      //       console.log( '123' )
      //     }
      //   }
      // } )
    },
    methods: {}
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";

  .notifications {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
    z-index: 21;

    transform: translateY(100vh);

    transition: transform 1ms $transition-animate;

    &.show {
      transform: translateY(0);

      transition: transform 1ms;
    }

    @media #{$media-mobile} {
      right: 1rem;
      bottom: 1rem;
      left: 1rem;
    }
  }
</style>
