<template>
  <div class="card" :class="classes">
    <div class="card-image">
      <div class="photo-loader" :class="photoLoaderclasses"/>
    </div>
    <div class="card-content">
      <h4 class="card-title placeholder"/>
      <!--<h5 class="card-subtitle placeholder"/>-->
    </div>
  </div>
</template>

<script>
  export default {
    name: 'gallery-item-placeholder',
    props: ['offset', 'alwaysGrid', 'animateIn', 'widescreen'],
    data() {
      return {
        loaded: false,
        error: false
      }
    },
    computed: {
      classes() {
        let classes = {}
        if( this.animateIn === true ) {
          classes['animate-zoom-in'] = true
          classes['animation-offset-' + this.offset] = true
        }
        if( this.$root.listView && this.alwaysGrid !== true ) {
          classes['list'] = true
        }
        return classes
      },
      photoLoaderclasses() {
        let classes = {}
        if( this.widescreen === true ) {
          classes['aspect-16-9'] = true
        }
        classes['animation-offset-' + this.offset] = true
        if( this.$root.listView && this.alwaysGrid !== true ) {
          classes['list'] = true
        }
        return classes
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  .placeholder {
    background-color: #CCC;

    &:before {
      content: "\a0";
    }
  }

  .card-title.placeholder {
    width: 50%;
    min-width: 15rem;
    padding-bottom: 0;
  }

  .card-subtitle.placeholder {
    width: 75%;
    min-width: 20rem;
    margin-top: 0.75rem;
  }
</style>
