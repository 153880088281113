<template>
  <footer v-if="!$root.standalone" class="container columns-on-tiny">
    <!--<div class="column width-full">-->
    <!--<hr>-->
    <!--</div>-->
    <!-- <div class="column width-1-4-small width-1-2-tiny social">
      <a href="https://www.facebook.com/smileonthetilesplymouth/" target="_blank" rel="noopener" title="See our Facebook page"><i class="fab fa-facebook fa-fw"></i> Facebook</a>
    </div>
    <div class="column width-1-4-small width-1-2-tiny social">
      <a href="https://www.instagram.com/smileonthetiles/" target="_blank" rel="noopener" title="See our Instagram feed"><i class="fab fa-instagram fa-fw"></i> Instagram</a>
    </div>
    <div class="column width-1-4-small width-1-2-tiny social">
      <a href="https://twitter.com/Smileonthetiles" target="_blank" rel="noopener" title="Read our Tweets"><i class="fab fa-twitter fa-fw"></i>Twitter</a>
    </div>
    <div class="column width-1-4-small width-1-2-tiny social">
      <a href="skype:smileonthetiles?call" title="Call us on Skype"><i class="fab fa-skype fa-fw"></i> Skype</a>
    </div> -->
    <div class="column width-full">
      <router-link to="/terms">Terms &amp; Conditions</router-link>
      |
      <router-link to="/privacy">Privacy Policy</router-link>
      | <a href="mailto:info@smileonthetiles.co.uk" title="Email us">info@smileonthetiles.co.uk</a>
    </div>
    <div class="column width-full">
      <small>&copy;2023 Smileonthetiles</small>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'site-footer-mini'
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  footer {
    padding-top: 5rem;

    color: #FFF;
    //font-size: 1.75rem;

    a {
      //display: block;

      color: #FFF;
    }
  }

  .container {
    padding-bottom: 3rem;
  }
</style>
