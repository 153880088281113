<template>
  <div v-if="notices.length" class="notices flexbox flexbox-align-center flexbox-justify-center">
    <div v-on:touchstart.prevent class="notices-mask"></div>
    <system-notice v-for="(notice, index) in notices" :key="index" :index="index" :notice="notice" v-on:close="remove"></system-notice>
  </div>
</template>

<script>
  import SystemNotice from './SystemNotice.vue'

  export default {
    name: 'system-notices',
    components: { SystemNotice },
    data() {
      return {
        notices: {}
      }
    },
    mounted() {
      /* global firebase */
      firebase.database()
        .ref( '/notices' )
        .once( 'value' )
        .then( snapshot => {
          if( snapshot.val() ) {
            const now = new Date().getTime() / 1000
            this.notices = snapshot.val().filter( notice => ( now > notice.start && now < notice.end && ( JSON.parse( window.localStorage.getItem( 'closedNotices' ) ) || [] ).indexOf( notice.id ) === -1 ) )
            // this.notices = [ {
            //   id: 1529616191,
            //   date: 1529616191,
            //   start: 1529616191,
            //   end: 1529971199,
            //   text: '<p>You can now favourite photos and view them in "My Smiles".</p>'
            // } ]
          }
        } )
    },
    methods: {
      remove: function( index ) {
        // console.log( id )
        // let index = -1;
        // for( let notification in this.notices ) {
        //   if( this.notices.hasOwnProperty( notification ) &&
        //     this.notices[notification].id === id ) {
        //     index = notification
        //     break
        //   }
        // }
        if( index > -1 ) {
          this.notices.splice( index, 1 )
        }
      },
      removeAll() {
        if( this.notices.length < 2 ) {
          this.notices = []
        }
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";

  .notices {
    position: fixed;
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
    z-index: 11;

    @media #{$media-mobile} {
      top: 3rem;
      right: 3rem;
      left: 3rem;
    }
  }

  .notices-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    background-color: rgba(0, 0, 0, 0.75);
  }
</style>
