<template>
  <router-link :to="'/galleries' + location.uri" :data-count="location.children_count.toLocaleString()" data-countunits="items" class="card hoverable" :class="classes">
    <img v-show="loaded && !error" :src="location.image.url" :alt="location.title" class="card-image">
    <img v-show="loaded && error" src="/images/awaiting-image.png" :alt="location.title" class="card-image">
    <div v-if="!loaded" class="card-image">
      <div class="photo-loader aspect-4-3" :class="'animation-offset-' + offset"/>
    </div>
    <div class="card-content">
      <h4 v-html="location.title" class="card-title"></h4>
      <h5 v-if="showParent === true && location.parent.title" class="card-subtitle">{{ location.parent.title }}</h5>
    </div>
  </router-link>
</template>

<script>
  export default {
    name: 'gallery-item-location',
    props: ['location', 'offset', 'showParent', 'alwaysGrid', 'animateIn'],
    data() {
      return {
        loaded: false,
        error: false
      }
    },
    computed: {
      classes() {
        let classes = {}
        if( this.animateIn === true ) {
          classes['animate-zoom-in'] = true
          classes['animation-offset-' + this.offset] = true
        }
        if( this.$root.listView && this.alwaysGrid !== true ) {
          classes['list'] = true
        }
        return classes
      }
    },
    mounted() {
      /* global Image */
      let img = new Image()
      img.src = this.location.image.url
      img.onload = () => {
        this.loaded = true
      }
      img.onerror = e => {
        console.error( e )
        this.loaded = true
        this.error = true
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
