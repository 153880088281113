<template>
  <div class="notification-wrapper" :class="{ dismissed: dismissed }">
    <div class="notification flexbox flexbox-align-center" :class="notification.style">
      <div v-if="notification.lifespan" class="lifetime-wrapper">
        <div class="lifetime" :style="{ animationDuration: notification.lifespan + 'ms' }"></div>
      </div>
      <!--<div>{{ index }}</div>-->
      <div class="notification-body flex-1" v-html="notification.text"></div>
      <template v-if="notification.actions">
        <template v-for="(action, label) in notification.actions">
          <button v-if="action === true" v-on:click.prevent="close" class="block thin" v-html="label"></button>
          <router-link v-else-if="typeof action === 'string'" :to="action" v-on:click.native="close" class="block thin" v-html="label"></router-link>
          <!--<button v-else-if="typeof action === 'function'" v-on:click.prevent="() => { action.call(); close() }" class="block thin" v-html="label"></button>-->
        </template>
      </template>
      <button v-else v-on:click.prevent="close">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'notification-chip',
    props: ['notification'],
    data() {
      return {
        dismissed: false,
        lifetimeTimeout: null
      }
    },
    mounted() {
      if( this.notification.lifespan ) {
        this.lifetimeTimeout = setTimeout( this.close, this.notification.lifespan )
      }
    },
    methods: {
      close() {
        this.dismissed = true
        clearTimeout( this.lifetimeTimeout )
        setTimeout( () => this.$store.commit( 'removeNotification', this.notification.id ), 400 )
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";

  .notification-wrapper {
    &.dismissed {
      transform: translateX(100vw);

      transition: transform 1ms $transition-animate;

      .notification {
        transform: translateX(100vw);
      }
    }
  }

  .notification {
    margin-top: 1rem;
    padding: 2rem 2rem 2rem 3rem;

    position: relative;

    overflow: hidden;

    background-color: #FFF;

    border-radius: $border-radius;

    @include shadow-6;

    transition: transform $transition-animate ease-in;

    &::before {
      width: 1rem;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      content: "";
    }

    &.error {
      &::before {
        background-color: #C00;
      }
    }
  }

  .lifetime-wrapper {
    height: 0.5rem;

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .lifetime {
    width: 0;
    height: 100%;

    background-color: $primary-colour;

    animation-name: lifetime;
    animation-timing-function: linear;
    animation-duration: $transition-hover-in;
    animation-fill-mode: forwards;
  }

  .notification-body {
    margin-right: 2rem;

    p {
      padding-bottom: 0.5rem;
    }
  }

  @keyframes lifetime {
    0% {
      width: 100%;
    }

    100% {
      width: 0;
    }
  }
</style>
