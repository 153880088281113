<template>
  <div class="page flex-1 flexbox-vertical">
    <header class="header">
      <div class="container">
        <div class="column full">
          <h1>Sign in to Smileonthetiles</h1>
        </div>
      </div>
    </header>
    <main class="flex-1 flexbox flexbox-align-center">
      <div class="flex-1 container flexbox-justify-center">
        <div v-if="!$root.standalone" class="column flex-1 flex-0-mobile width-full-mobile welcome">
          <h3>Become a member</h3>
          <p>If you are under the age of 18, we request that you DO NOT have your photo taken by our photographers unless you are accompanied by a responsible consenting adult! Please read all terms and conditions before using this website.</p>
          <p>
            <i class="material-icons">photo_library</i> <strong>Access to thousands of albums</strong>
          </p>
          <p>
            <i class="material-icons">favorite</i> <strong>Favourites</strong>
          </p>
          <p class="warning">
            <strong>GDPR</strong><br>We try to store as little data as possible; we save your email address so you can do things like report photos. By signing in, you agree to let us store your email address.
          </p>
        </div>
        <div class="column width-full-mobile sign-in-form-wrapper">
          <div class="sign-in-form">
            <h2>Sign in</h2>
            <p>1. Sign in with Facebook or Google</p>
            <sign-in-third-party></sign-in-third-party>
            <hr data-title="or">
            <p>2. Sign in with your email address and we'll send you a login link</p>
            <div class="sign-in-email flexbox flexbox-align-stretch">
              <i class="material-icons">alternate_email</i>
              <input type="email" v-model="email" v-on:keyup.enter="signIn" v-on:keyup="error = null" placeholder="Email address" autocomplete="email" class="flex-1"/>
              <button v-on:click="signIn" title="Sign in">
                <i class="material-icons">arrow_forward</i>
              </button>
            </div>
            <p v-if="email && email.match( /\.con$/ )" class="warning invalid-email-warning">Are you sure this email address is right?</p>
            <p v-if="emailSent" class="success">An email was sent to
              <strong v-html="emailSent"></strong>. Please use the link in the email to sign-in.</p>
            <p v-if="error" class="error" v-html="error"></p>
          </div>
        </div>
      </div>
    </main>
    <site-footer/>
  </div>
</template>

<script>
  import SignInThirdParty from '../components/SignInThirdParty.vue'
  import SiteFooter from '../components/SiteFooter.vue'

  export default {
    name: 'sign-in',
    components: {
      SignInThirdParty,
      SiteFooter
    },
    data() {
      return {
        email: '',
        password: '',
        emailSent: null,
        error: null
      }
    },
    beforeCreate() {
      /* global firebase */
      if( firebase.auth().currentUser ) {
        this.$router.replace( '/' )
      }
    },
    mounted() {
      /* global firebase */
      if( firebase.auth().isSignInWithEmailLink( window.location.href ) ) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let email = window.localStorage.getItem( 'emailForSignIn' )
        if( !email ) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt( 'Please provide your email for confirmation' )
        }
        // The client SDK will parse the code from the link for you.
        firebase.auth()
          .signInWithEmailLink( email, window.location.href )
          .then( result => {
            // Clear email from storage.
            window.localStorage.removeItem( 'emailForSignIn' )
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            let reroute = this.$store.state.persistent.redirectAfterLogin || '/'
            this.$store.commit( 'redirectAfterLogin', '/' )
            this.$router.replace( reroute )
          } )
          .catch( error => {
            console.error( error, error.code )
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          } )
      }
    },
    methods: {
      signIn() {
        this.error = null

        let actionCodeSettings = {
          // URL you want to redirect back to. The domain (www.example.com) for this URL
          // must be whitelisted in the Firebase Console.
          url: window.location.href, // Here we redirect back to this same page.
          handleCodeInApp: true // This must be true.
        }

        firebase.auth()
          .sendSignInLinkToEmail( this.email, actionCodeSettings )
          .then( () => {
            // Save the email locally so you don’t need to ask the user for it again if they open the link on the same device.
            window.localStorage.setItem( 'emailForSignIn', this.email )
            this.emailSent = this.email
            this.email = ''
          } )
          .catch( error => {
            let errorCode = error.code
            let errorMessage = error.message
            console.error( errorCode, errorMessage )
            this.error = errorMessage
          } )
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";

  .welcome {
    margin-bottom: 4rem;

    p {
      .material-icons {
        margin-right: 1rem;
      }
    }
  }

  .sign-in-form-wrapper {
    /*flex-basis: 728px;*/
  }

  .sign-in-form {
    padding: 3rem;

    position: relative;

    overflow: hidden;

    background-color: #F9F9F9;

    border-radius: $border-radius;

    @include shadow-2;

    h2 {
      margin: -3rem -3rem 3rem -3rem;
      padding: 2rem 3rem;

      color: #FFF;

      background-color: $primary-colour;
    }

    input {
      display: block;

      background-color: #FFF;
    }

    .sign-in-email {
      height: 6rem;

      overflow: hidden;

      line-height: 6rem;

      background-color: #FFF;

      border-radius: $border-radius;

      @include shadow-2;

      .material-icons {
        padding: 0 2rem;

        line-height: inherit;
      }

      input,
      button {
        display: block;

        border: none;
      }

      input {
        font-size: 2.5rem;
      }

      button {
        padding: 0;

        color: inherit;
        font-size: 2.5rem;

        background-color: transparent;

        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .invalid-email-warning {
    margin-top: 2rem;
  }

  .success,
  .error {
    margin-top: 2rem;
  }
</style>
