export default function numberSuffix(
    intNumber: number,
    blIncludeNumber: boolean = false,
) {
    if (typeof intNumber !== 'number') {
        intNumber = parseInt(intNumber, 10);
    }
    let strSuffix = '';
    if (typeof intNumber === 'number' && intNumber > 0) {
        const intNumberTensUnits = parseInt(intNumber.toString().substr(-2), 10);
        const intNumberUnits = parseInt(intNumber.toString().substr(-1), 10);

        if (intNumberUnits === 1 && intNumberTensUnits !== 11) {
            strSuffix = 'st';
        } else if (intNumberUnits === 2 && intNumberTensUnits !== 12) {
            strSuffix = 'nd';
        } else if (intNumberUnits === 3 && intNumberTensUnits !== 13) {
            strSuffix = 'rd';
        } else {
            strSuffix = 'th';
        }
    }
    return blIncludeNumber === true ? intNumber + strSuffix : strSuffix;
}
