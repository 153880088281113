<template>
  <div class="page flex-1 flexbox-vertical">
    <div v-if="loading" class="flex-1 flexbox-vertical">
      <div class="flex-1 flexbox-vertical">
        <header class="header">
          <div class="container flexbox-align-center columns-on-tiny">
            <div class="column full">
              <h1 class="gallery-title">&hellip;</h1>
            </div>
            <div v-if="!$root.standalone" class="column flex-1">
              <span class="backlink">
                <i class="material-icons">more</i> <span>&hellip;</span>
              </span>
            </div>
            <div v-if="!$root.standalone" class="column full">
              <search-bar/>
            </div>
          </div>
        </header>
        <standalone-sub-header :loading="true"/>
        <main class="flex-1">
          <div class="container flexbox-align-start">
            <div class="loader inline"/>
          </div>
        </main>
      </div>
    </div>
    <div v-else-if="errorCode" class="page flex-1 flexbox-vertical">
      <main class="flex-1 flexbox flexbox-align-center">
        <div class="container flexbox-justify-center">
          <div class="column large-text">
            <h1 v-html="errorCode"></h1>
            <p v-html="errorMessage"></p>
            <router-link to="/" class="router-link-active">&larr; Go home</router-link>
          </div>
        </div>
      </main>
      <site-footer/>
    </div>
    <template v-else>
      <gallery-page-location v-if="currentItem.type === 'location'" :location="currentItem"></gallery-page-location>
      <gallery-page-venue v-else-if="currentItem.type === 'venue'" :venue="currentItem"></gallery-page-venue>
      <gallery-page-album v-else-if="currentItem.type === 'album'" :album="currentItem"></gallery-page-album>
    </template>
  </div>
</template>

<script>
  import FetchJSON from '@/libs/FetchJSON.ts'
  import StandaloneSubHeader from '@/components/StandaloneSubHeader'
  import SearchBar from '@/components/SearchBar.vue'
  import GalleryPageLocation from './galleries/GalleryPageLocation.vue'
  import GalleryPageVenue from './galleries/GalleryPageVenue.vue'
  import GalleryPageAlbum from './galleries/GalleryPageAlbum.vue'
  import GalleryItemPlaceholder from '@/components/gallery/GalleryItemPlaceholder'
  import SiteFooter from '@/components/SiteFooter.vue'
  import Meta from '@/modules/Meta'

  export default {
    name: 'gallery',
    components: {
      StandaloneSubHeader,
      SearchBar,
      GalleryPageLocation,
      GalleryPageVenue,
      GalleryPageAlbum,
      GalleryItemPlaceholder,
      SiteFooter
    },
    data() {
      return {
        currentItem: {},
        loading: true,
        errorCode: null,
        errorMessage: null
      }
    },
    computed: {
      columnSizes() {
        if( this.$root.standalone && this.$store.state.persistent.listView ) {
          return 'full'
        } else {
          return 'width-1-3 width-1-2-medium width-1-2-small'
        }
      }
    },
    mounted() {
      FetchJSON( this.$root.apiURL + '/structure/' + ( this.$route.params.galleryURI || '' ) )
        .then( response => {
          this.currentItem = response
          this.loading = false
          let title = 'Galleries'
          if( this.currentItem && this.currentItem.parent && this.currentItem.parent.title ) {
            title = this.currentItem.parent.title + ' | ' + title
          }
          if( this.currentItem.title ) {
            title = this.currentItem.title + ' | ' + title
          }
          Meta.title( title )
        } )
        .catch( response => {
          console.error( response )
          this.errorCode = response.status
          this.errorMessage = response.statusText
          this.loading = false
        } )
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";

  .gallery-title {
    margin-bottom: 2rem;

    .standalone & {
      margin-bottom: 0;
    }
  }
</style>
