<template>
  <div id="firebaseui-auth-container"></div>
</template>

<script>
  export default {
    name: 'sign-in-third-party',
    data() {
      return {
        ui: null
      }
    },
    mounted() {
      /* global firebaseui */
      this.ui = new firebaseui.auth.AuthUI( firebase.auth() )

      this.ui.start( '#firebaseui-auth-container', {
        callbacks: {
          signInSuccessWithAuthResult: ( authResult, redirectUrl ) => {
            let currentUser = firebase.auth().currentUser
            currentUser.getIdToken( true )
              .then( idToken => {
                this.$store.commit( {
                  type: 'loggedIn',
                  uid: currentUser.uid,
                  displayName: currentUser.displayName,
                  email: currentUser.email,
                  photoURL: currentUser.photoURL,
                  jwt: idToken
                } )
                this.$router.push( {
                  path: this.$store.state.persistent.redirectAfterLogin || '/'
                } )
                this.$store.commit( 'redirectAfterLogin', '' )
              } )
            return false
          },
          signInFailure: error => {
            console.error( error )
          }
        },
        signInFlow: 'popup',
        signInOptions: [
          /* global firebase */
          // firebase.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          firebase.auth.GoogleAuthProvider.PROVIDER_ID
        ],
        signInSuccessUrl: this.$store.state.persistent.redirectAfterLogin || '/',
        tosUrl: '/terms'
      } )
    },
    beforeDestroy() {
      if( this.ui ) {
        this.ui.delete()
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
