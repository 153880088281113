<template>
  <div class="page flex-1 flexbox-vertical">
    <main class="flex-1 flexbox flexbox-align-center">
      <div class="container flexbox-justify-center">
        <div class="column large-text">
          <h1>404</h1>
          <p>Not Found</p>
          <router-link to="/">&larr; Go home</router-link>
        </div>
      </div>
    </main>
    <site-footer/>
  </div>
</template>

<script>
  import SiteFooter from '../components/SiteFooter.vue'

  export default {
    name: 'page-not-found',
    components: { SiteFooter }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
