<template>
  <div class="notice flexbox-vertical" :class="{ dismissed: dismissed }">
    <div class="notice-body">
      <p v-if="notice.date" class="date"><strong>{{ PrettyDate( 'jS F Y', notice.date ) }}</strong></p>
      <div v-html="notice.text"></div>
    </div>
    <!--<button v-on:click.prevent="close">-->
    <!--<i class="material-icons">close</i>-->
    <!--</button>-->
    <button v-on:click.prevent="close" class="block inverted" v-html="notice.button || 'Okay'"></button>
  </div>
</template>

<script>
  import PrettyDate from '../libs/PrettyDate'

  export default {
    name: 'system-notice',
    props: ['index', 'notice'],
    data() {
      return {
        dismissed: false
      }
    },
    methods: {
      close() {
        this.dismissed = true
        let closedNotices = JSON.parse( window.localStorage.getItem( 'closedNotices' ) ) || []
        if( closedNotices.indexOf( this.notice.id ) === -1 ) {
          closedNotices.push( this.notice.id )
          window.localStorage.setItem( 'closedNotices', JSON.stringify( closedNotices ) )
        }
        setTimeout( () => this.$emit( 'close', this.index ), 400 )
      },
      PrettyDate: PrettyDate
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";

  .notice {
    width: 100rem;
    max-width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    /*padding: 2rem 2rem 4rem 3rem;*/
    padding: 4rem 3rem;

    color: #FFF;

    background-color: $primary-colour;

    border-radius: $border-radius;

    @include shadow-6;

    transition: transform 200ms ease-in;

    &.dismissed {
      transform: translateX(100vw);
    }
  }

  .notice-body {
    /*margin-right: 2rem;*/
    /*padding-top: 2rem;*/
    padding-bottom: 3rem;

    .date {
      margin-bottom: 1rem;
    }

    p {
      padding-bottom: 0.5rem;
    }
  }
</style>
