<template>
  <router-link :to="'/galleries' + venue.uri" :data-label="label" :data-count="venue.children_count.toLocaleString()" data-countunits="albums" class="card hoverable" :class="classes">
    <img v-show="loaded && !error" :src="venue.image.url" :alt="venue.title" class="card-image">
    <img v-show="loaded && error" src="/images/awaiting-image.png" class="card-image">
    <div v-if="!loaded" class="card-image">
      <div class="photo-loader aspect-16-9" :class="'animation-offset-' + offset"></div>
    </div>
    <div class="card-content">
      <h4 v-html="venue.title" class="card-title"></h4>
      <h5 v-if="showParent === true && venue.parent.title" class="card-subtitle">{{ venue.parent.title }}</h5>
    </div>
  </router-link>
</template>

<script>
  export default {
    name: 'gallery-item-venue',
    props: ['venue', 'offset', 'showParent', 'alwaysGrid', 'animateIn'],
    data() {
      return {
        loaded: false,
        error: false,
        label: this.venue.meta.closed === true ? 'Closed' : ''
      }
    },
    computed: {
      classes() {
        let classes = {}
        if( this.animateIn === true ) {
          classes['animate-zoom-in'] = true
          classes['animation-offset-' + this.offset] = true
        }
        if( this.venue.meta.closed ) {
          classes['label-red'] = true
        }
        if( this.$root.listView && this.alwaysGrid !== true ) {
          classes['list'] = true
        }
        return classes
      }
    },
    mounted() {
      /* global Image */
      let img = new Image()
      img.src = this.venue.image.url
      img.onload = () => {
        this.loaded = true
      }
      img.onerror = e => {
        this.loaded = true
        this.error = true
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
