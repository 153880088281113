<template>
  <div class="page flex-1 flexbox-vertical">
    <header class="header">
      <div class="container">
        <div class="column full">
          <h1>Signing out&hellip;</h1>
        </div>
      </div>
    </header>
    <main class="flex-1">
      <div class="container">
        <div class="column full">
          <h2>:(</h2>
        </div>
      </div>
    </main>
    <site-footer/>
  </div>
</template>

<script>
  import SiteFooter from '../components/SiteFooter.vue'

  export default {
    name: 'sign-out',
    components: { SiteFooter },
    data() {
      return {
        signedOut: false
      }
    },
    mounted() {
      console.log( this.$router )
      /* global firebase */
      firebase.auth()
        .signOut()
        .then(
          () => {
            this.signedOut = true
            this.$store.commit( 'notify', {
              text: 'Logged out successfully',
              lifespan: 5000
            } )
            this.$router.replace( '/' )
          },
          error => {
            console.error( error.message )
          }
        )
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
