import getUserToken from '../firebase/UserToken';

const FetchJSON = (url: string) => {
    return getUserToken()
        .then((token) => new Promise((resolve, reject) => {
                if (token) {
                    if (url.indexOf('?') > -1) {
                        url += '&jwt=' + encodeURIComponent(token);
                    } else {
                        url += '?jwt=' + encodeURIComponent(token);
                    }
                }
                const request = new Request(url, {
                    method: 'GET',
                    // redirect: 'follow',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                    },
                });
                fetch(request)
                    .then((response) => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            return reject({
                                status: response.status,
                                response,
                            });
                        }
                    })
                    .then((json) => resolve(json))
                    .catch((e) => reject(e));
            }),
        );
};

export default FetchJSON;
