<template>
  <div class="page flex-1 flexbox-vertical">
    <header class="header">
      <div class="container columns-on-tiny flexbox-align-end">
        <div class="column flex-1">
          <h1 v-if="results && !$root.standalone">Search for &quot;{{ query }}&quot;</h1>
          <h1 v-else>Search</h1>
          <p v-if="!$root.standalone">Search for a date, venue or location</p>
        </div>
        <div v-if="results" class="column align-right">
          <p>
            <span v-if="!$root.standalone">{{ results.stats.results_count.toLocaleString() }} results found<br></span>
            <small v-if="results.stats.pages">Page {{ results.criteria.page }} of {{ results.stats.pages }}</small>
          </p>
        </div>
        <div class="column full">
          <search-bar/>
          <p v-if="results && results.criteria.recognised_date">
            <small>Date match: &quot;{{ results.criteria.recognised_date_string }}&quot; &rarr; {{ results.criteria.recognised_date }}</small>
          </p>
        </div>
      </div>
    </header>
    <main class="flex-1">
      <template v-if="!query">
        <div class="container flexbox-justify-center">
          <div class="column width-1-2 width-2-3-medium width-full-mobile align-center">
            <h4>Example searches</h4>
            <ul class="plain">
              <li>
                <router-link to="?q=last+sat">last sat</router-link>
              </li>
              <li>
                <router-link to="?q=last+friday+union+rooms">last friday union rooms</router-link>
              </li>
              <li>
                <router-link to="?q=barbican+2+saturdays+ago">barbican 2 saturdays ago</router-link>
              </li>
              <li>
                <router-link to="?q=walkabout+31+dec+16">walkabout 31 dec 16</router-link>
              </li>
              <li>
                <router-link to="?q=28th+november+2015+lavish">28th november 2015 lavish</router-link>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-else-if="searching">
        <div class="container">
          <div class="column full">
            <p>Searching&hellip;</p>
          </div>
        </div>
      </template>
      <template v-else-if="results">
        <div class="container">
          <template v-if="results.results.length > 0">
            <template v-for="(result, index) in results.results">
              <div class="column width-1-4 width-1-3-medium width-1-2-small">
                <gallery-item-location v-if="result.type === 'location'" :offset="index" :location="result" :showParent="true" :alwaysGrid="true"/>
                <gallery-item-venue v-else-if="result.type === 'venue'" :offset="index" :venue="result" :showParent="true" :alwaysGrid="true"/>
                <gallery-item-album v-else-if="result.type === 'album'" :offset="index" :album="result" :showParent="true" :alwaysGrid="true"/>
              </div>
            </template>
            <div v-if="results.stats.pages > 1" class="column full">
              <pagination :currentPage="parseInt( results.criteria.page )" :pages="parseInt( results.stats.pages )" :padding="3" v-on:navigateTo="navigateToPage"></pagination>
            </div>
          </template>
          <div v-else class="column">
            <h2>Sorry, there are no results found for &quot;{{ $route.query.q }}&quot;</h2>
            <p>Please refine your search</p>
          </div>
        </div>
      </template>
    </main>
    <site-footer/>
  </div>
</template>

<script>
  import FetchJSON from '../libs/FetchJSON.ts'
  import SearchBar from '../components/SearchBar.vue'
  import GalleryItemLocation from '../components/gallery/GalleryItemLocation.vue'
  import GalleryItemVenue from '../components/gallery/GalleryItemVenue.vue'
  import GalleryItemAlbum from '../components/gallery/GalleryItemAlbum.vue'
  import Pagination from '../components/Pagination.vue'
  import SiteFooter from '../components/SiteFooter.vue'

  export default {
    name: 'search',
    components: {
      SearchBar,
      GalleryItemLocation,
      GalleryItemVenue,
      GalleryItemAlbum,
      Pagination,
      SiteFooter
    },
    data() {
      return {
        searching: false,
        results: null,
        page: this.$route.query.page || 1,
        query: this.$route.query.q || ''
      }
    },
    computed: {
      apiURL() {
        return this.$root.apiURL + '/search/' + this.query.replace( /\//g, '-' ) + '/24/' + this.page
      }
    },
    created() {
      if( this.query ) {
        this.searching = true
        this.getResults()
      }
    },
    methods: {
      getResults() {
        this.searching = true
        FetchJSON( this.apiURL )
          .then( response => {
            this.results = response
            this.searching = false
          } )
          .catch( e => {
            console.error( e )
            this.searching = false
          } )
      },
      navigateToPage: function( i ) {
        this.page = i
        this.$router.push( { query: { q: this.query, page: this.page } } )
      }
    },
    watch: {
      page() {
        this.getResults()
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";

  input {
    color: #000;
  }
</style>
