<template>
  <div v-if="$root.standalone" class="sub-header">
    <div class="container columns-on-tiny flexbox-nowrap flexbox-justify-stretch flexbox-align-stretch">
      <div v-if="backLink" class="column flexbox flexbox-align-center">
        <router-link :to="backLink" class="backlink">
          <i class="material-icons">more</i> <span>{{ backLinkTitle || 'Back' }}</span>
        </router-link>
      </div>
      <div v-else-if="loading" class="column flexbox flexbox-align-center">
        <span :to="backLink" class="backlink">
          <i class="material-icons">more</i> <span>&hellip;</span>
        </span>
      </div>
      <div class="flex-1"/>
      <slot v-if="!loading"/>
      <div v-if="$root.canShare && shareURL && !loading" class="column buttons">
        <button v-on:click.prevent="share">
          <i class="material-icons">share</i>
        </button>
      </div>
      <div v-if="!hideViews && !loading" class="column buttons no-wrap">
        <button v-on:click="gridView" :class="{ inactive: $root.listView }">
          <i class="material-icons">view_module</i>
        </button>
        <button v-on:click="listView" :class="{ inactive: !$root.listView }">
          <i class="material-icons">view_stream</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'standalone-sub-header',
    props: ['loading', 'backLink', 'backLinkTitle', 'hideViews', 'shareURL', 'shareTitle', 'shareText'],
    methods: {
      listView() {
        this.$store.commit( 'switchListView', true )
      },
      gridView() {
        this.$store.commit( 'switchListView', false )
      },
      share() {
        navigator.share( {
          title: this.shareTitle || 'Smileonthetiles',
          text: this.shareText || 'Check this out on Smileonthetiles',
          url: this.shareURL
        } )
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";

  .sub-header {
    padding-top: 1rem;
    padding-bottom: 1rem;

    position: sticky;
    top: 0;
    z-index: 2;

    @include shadow-4;

    background-color: $primary-colour;

    > .container {
      min-height: 4rem;
    }
  }

  .backlink {
    color: #FFF;
    //color: $base-font-colour;

    display: block;

    text-decoration: none;
    line-height: 4rem;

    .material-icons {
      margin-right: 1rem;

      color: $primary-colour-really-light;

      transition: color $transition-hover-out;
    }

    &:hover {
      .material-icons {
        color: #FFF;

        transition: color $transition-hover-in;
      }
    }
  }

  .buttons {
    button {
      width: 4rem;
      height: 4rem;

      color: #FFF;

      &.inactive {
        opacity: 0.35;

        transition: opacity $transition-animate;
      }
    }
  }
</style>
