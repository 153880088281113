<template>
  <footer v-if="!$root.standalone" class="container">
    <div class="column flex-1">
      <p><a href="mailto:info@smileonthetiles.co.uk" title="Email us">info@smileonthetiles.co.uk</a></p>
      <!-- <p><a href="tel:+441752260245" title="Call us">01752 260245</a></p> -->
      <p>
        <small>Designed and built by <a href="https://andrewhosgood.dev" target="_blank" rel="noopener">AJ</a>
        </small>
      </p>
      <p>
        <small>Proudly powered by <a href="https://twistphp.com/" target="_blank" rel="noopener">TwistPHP</a></small>
      </p>
    </div>
    <div class="pipe hide-on-tiny"></div>
    <hr class="width-full-tiny"/>
    <!-- <div class="column flex-1 no-padding">
      <div class="container columns-on-tiny no-padding"> -->
        <!-- <div class="column width-full width-1-2-tiny social">
          <a href="https://www.facebook.com/smileonthetilesplymouth/" target="_blank" rel="noopener" title="See our Facebook page"><i class="fab fa-facebook fa-fw"></i> Facebook</a>
        </div> -->
        <!-- <div class="column width-full width-1-2-tiny social">
          <a href="https://www.instagram.com/smileonthetiles/" target="_blank" rel="noopener" title="See our Instagram feed"><i class="fab fa-instagram fa-fw"></i> Instagram</a>
        </div> -->
        <!-- <div class="column width-full width-1-2-tiny social">
          <a href="https://twitter.com/Smileonthetiles" target="_blank" rel="noopener" title="Read our Tweets"><i class="fab fa-twitter fa-fw"></i>Twitter</a>
        </div> -->
        <!-- <div class="column width-full width-1-2-tiny social">
          <a href="skype:smileonthetiles?call" title="Call us on Skype"><i class="fab fa-skype fa-fw"></i> Skype</a>
        </div> -->
      <!-- </div>
    </div> -->
    <div class="pipe hide-on-medium hide-on-mobile"></div>
    <hr class="width-full-medium width-full-mobile"/>
    <div class="column flex-2 width-full-medium- width-full-mobile flex-auto-mobile">
      <p><strong>Smileonthetiles</strong><br/>Plymouth's committed and legendary premiere nightlife photography
      <p>
        <small>
          <router-link to="/terms">Terms &amp; Conditions</router-link>
          |
          <router-link to="/privacy">Privacy Policy</router-link>
        </small>
      </p>
      <p>
        <small>&copy;2023 Smileonthetiles</small>
      </p>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'site-footer'
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  footer {
    padding-top: 5rem;
    padding-bottom: 5rem;

    color: #FFF;

    /*background-color: #424242;*/
    /*background-color: #F5F5F5;*/

    a {
      color: #FFF;
    }
  }

  .container {
    /*padding-bottom: 5rem;*/
  }

  .pipe {
    width: 1px;

    background-color: rgba(255, 255, 255, 0.15);
  }

  p {
    padding-bottom: 0.5rem;
  }
</style>
