export function ltrim(raw: string, trimChars: string = ' ') {
    return raw.replace(
        new RegExp('^[' + trimChars.replace('-', '\\-') + ']+'),
        '',
    );
}

export function rtrim(raw: string, trimChars: string = ' ') {
    return raw.replace(
        new RegExp('[' + trimChars.replace('-', '\\-') + ']+$'),
        '',
    );
}

export function trim(raw: string, trimChars: string = ' ') {
    return ltrim(rtrim(raw, trimChars), trimChars);
}

export function slug(raw: string, allowSlashes = false) {
    return trim(
        raw
            .replace(/[.,;:]/g, '-')
            .replace(
                allowSlashes === true ? /[^a-z0-9\s+\-_/]/gi : /[^a-z0-9\s+\-_]/gi,
                '',
            )
            .replace(/\s{2,}/g, ' ')
            .replace(/\s/g, '-')
            .replace(/-{2,}/g, '-')
            .toLowerCase(),
        ' -',
    );
}

export function padLeft(
    raw: string,
    intFinalLength: number,
    strPadChar: string = ' ',
) {
    raw = raw.toString();
    if (raw.length < intFinalLength) {
        for (let intChar = 0; intChar < intFinalLength; intChar++) {
            raw = strPadChar + raw;
        }
    }
    return raw.slice(-intFinalLength);
}

export function padWithZeroes(numToPad: number, padBy: number) {
    let len = padBy;
    let out = numToPad.toString();
    while (--len) {
        out = '0' + out;
    }
    return out.slice(-padBy);
}
