import PhotoSwipe, {Item} from 'photoswipe';
import PhotoSwipeUI_Default, {Options} from 'photoswipe/dist/photoswipe-ui-default';

export default class Gallery {

    public static canAutoOpen() {
        const hashData = Gallery.getHashData();
        return hashData.pid && hashData.gid;
    }

    public static getHashData() {
        const hash = window.location.hash.replace(/(^[&#]+|&+$)/g, '');
        const params: { gid?: number, pid?: number, [key: string]: any } = {};
        const vars = hash.split('&');
        for (const i of vars) {
            if (!i) {
                continue;
            }
            const pair = i.split('=');
            if (pair.length < 2) {
                continue;
            }
            switch (pair[0]) {
                case 'gid':
                case 'pid':
                    params[pair[0]] = parseInt(pair[1], 10);
                    break;
                default:
                    params[pair[0]] = pair[1];
                    break;
            }
        }
        return params;
    }

    public items: Item[];
    public photoswipe: any;
    public defaultOptions: Options;
    private galleryContainer: HTMLElement | null;

    constructor(albumID: number, items: Item[]) {
        this.galleryContainer = document.getElementById('photoswipe-wrapper');
        this.items = items;
        this.defaultOptions = {
            bgOpacity: 0.9,
            closeOnScroll: false,
            index: 0,
            galleryUID: albumID,
            getThumbBoundsFn: (index: number) => {
                const image = document.querySelector('.gallery-item-photo[data-index="' + index + '"] img');
                if (image) {
                    const imageRect = image.getBoundingClientRect();
                    return {
                        x: imageRect.left + window.scrollX,
                        y: imageRect.top + window.scrollY,
                        w: imageRect.width,
                    };
                } else {
                    return {
                        x: 0,
                        y: 0,
                        w: 0,
                    };
                }
            },
            preload: [1, 3],
            shareButtons: [
                {
                    id: 'facebook',
                    label: '<i class="fa fa-fw fa-facebook-official"></i> Post to Facebook',
                    url: 'https://www.facebook.com/dialog/' +
                        'share?app_id=1034584839972494&display=popup&href={{image_url}}',
                },
                {
                    id: 'twitter',
                    label: '<i class="fa fa-fw fa-twitter"></i> Tweet',
                    url: 'https://twitter.com/intent/tweet?url={{image_url}}',
                },
            ],
        };
        this.init();
    }

    public init(index: number | null = null): void {
        if (index !== null) {
            this.defaultOptions.index = index;
        }
        if (this.galleryContainer) {
            this.photoswipe = new PhotoSwipe(
                this.galleryContainer,
                PhotoSwipeUI_Default,
                this.items,
                this.defaultOptions,
            );
        } else {
            // TODO: Error
        }
    }

    public open(index: number | null = null): void {
        if (index !== null) {
            this.defaultOptions.index = index;
        }
        this.photoswipe.init();
        this.photoswipe.shout('ready');
    }
}
