// import 'babel-polyfill';
// import 'whatwg-fetch';
import Vue from 'vue';
import Store from '@/modules/Store';
import Router from '@/modules/Router';
import App from '@/App.vue';
import FirebaseConfig from '@/firebase/Config';
// import FirebaseMessaging from '@/firebase/Messaging';
// import LoadCSS from '@/libs/LoadCSS'
import Meta from '@/modules/Meta';
import FetchPOST from '@/libs/FetchPOST';
import Config from '@/Config';
// import './registerServiceWorker';

// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';

// Sentry.init({
//     dsn: 'https://c612209822984fb5b64f8eb9deee1f49@sentry.io/1457744',
//     integrations: [
//         new Integrations.Vue({
//             Vue,
//             attachProps: true,
//         }),
//     ],
// });

declare var ga: any;
try {
  if (ga) {
    ga('create', 'UA-131134051-1', 'auto');
    ga('send', 'pageview');
    ga('set', 'allowAdFeatures', false);
    ga('set', 'anonymizeIp', true);
  }
} catch (e) {
  // console.error(e);
}

Vue.config.productionTip = false;

window.firebase.initializeApp(FirebaseConfig);
// FirebaseMessaging.init();
//
// if ( FirebaseMessaging.permissionGranted() ) {
//     FirebaseMessaging.getToken()
//         .then( ( token: string ) => {
//             FetchPOST(Config.apiURL + '/topics/debug', {token})
//                 .then( (response) => {
//                     console.log( response );
//                 });
//         });
// }

let app: Vue;
const nav: any = window.navigator;

const Data: {
    standalone: boolean;
    deferredInstallPrompt: any | null;
    canShare: boolean;
    messagingSupported: boolean;
    apiURL: string;
} = {
    standalone: false,
    deferredInstallPrompt: null,
    canShare: !!nav.share,
    // messagingSupported: FirebaseMessaging.isSupported(),
    messagingSupported: false,
    apiURL: Config.apiURL,
};

const createApp = () => {
    if (!app) {
        app = new Vue({
            el: '#app',
            render: (h) => h(App),
            data: Data,
            router: Router,
            store: Store,
            beforeCreate() {
                this.$store.commit('initialiseStore');
            },
            created() {
                this.standalone =
                    sessionStorage.getItem('standalone') === 'true' ||
                    (!ie &&
                        URLSearchParams &&
                        new URLSearchParams(window.location.search).has('standalone')) ||
                    false;
                sessionStorage.setItem('standalone', this.standalone ? 'true' : 'false');
                window.addEventListener('beforeinstallprompt', (e) => {
                    e.preventDefault();
                    this.deferredInstallPrompt = e;
                });
            },
            methods: {
                setTitle(title = null) {
                    Meta.title(title);
                },
                setDescription(description: string) {
                    Meta.description(description);
                },
            },
            computed: {
                listView(): boolean {
                    return this.standalone && Store.state.persistent.listView;
                },
            },
        });
    }
};

window.firebase.auth()
    .onAuthStateChanged((user: { [key: string]: any }) => {
        if (user) {
            Store.commit('loggedIn', {
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
                photoURL: user.photoURL,
            });
            createApp();

            // firebase.database()
            //   .ref( 'notices' )
            //   .set( [ {
            //     id: 1529616191,
            //     date: 1529616191,
            //     start: 1529616191,
            //     end: 1529971199,
            //     text: '<p>You can now favourite photos and view them in "My Smiles".</p>',
            //   } ] );

            //
            // firebase.database()
            //   .ref( '/users/' + firebase.auth().currentUser.uid )
            //   .once( 'value' )
            //   .then( snapshot => {
            //   if(!snapshot.val() || !snapshot.val().favourites) {
            //     firebase.database()
            //     .ref( 'users/' + firebase.auth().currentUser.uid )
            //     .set( {
            //       favourites: [1]
            //     } )
            //   }
            //   } )
        } else {
            Store.commit('loggedOut');
            createApp();
        }
    });

const userAgent = navigator.userAgent;
const ie = userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;
if (ie) {
    document.body.classList.add('ie');
}

// if ('serviceWorker' in navigator) {
//   navigator
//     .serviceWorker
//     .register('/sw.js');
// }
