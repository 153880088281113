<template>
  <div class="flex-1 flexbox-vertical" :data-databaseid="location.id" :data-cachecreated="location.cache_created">
    <header class="header">
      <div class="container flexbox-align-center columns-on-tiny">
        <div class="column full">
          <h1 v-if="location.title" v-html="location.title" class="location-title"></h1>
          <h1 v-else class="location-title">Galleries</h1>
        </div>
        <div v-if="!$root.standalone" class="column flex-1">
          <router-link v-if="!topLevel" :to="'/galleries' + location.parent.uri" class="backlink">
            <i class="material-icons">more</i> <span>Back to {{ location.parent.title }}</span>
          </router-link>
        </div>
        <div v-if="!$root.standalone" class="column full">
          <search-bar/>
        </div>
      </div>
    </header>
    <standalone-sub-header :backLink="location.parent && location.parent.uri ? '/galleries' + location.parent.uri : null" :backLinkTitle="location.parent && location.parent.title ? location.parent.title : null"/>
    <main class="flex-1">
      <div class="container flexbox-align-start">
        <template v-if="loading">
          <template v-for="i in 6">
            <div class="column" :class="columnSizes">
              <gallery-item-placeholder :offset="i" :animateIn="true" :widescreen="true"/>
            </div>
          </template>
        </template>
        <template v-else>
          <template v-for="(child, index) in displayChildren">
            <div v-if="child.children_count" class="column" :class="columnSizes" :key="index">
              <gallery-item-location v-if="child.type === 'location'" :offset="index % location.children_per_call" :location="child"/>
              <gallery-item-venue v-else-if="child.type === 'venue'" :offset="index % location.children_per_call" :venue="child"/>
              <gallery-item-album v-else-if="child.type === 'album'" :offset="index % location.children_per_call" :album="child"/>
            </div>
          </template>
          <div v-if="pages > 1" class="column full">
            <pagination :currentPage="parseInt( page )" :pages="pages" :padding="2" v-on:navigateTo="navigateToPage"/>
          </div>
        </template>
      </div>
    </main>
    <site-footer/>
  </div>
</template>

<script>
  import FetchJSON from '../../libs/FetchJSON.ts'
  import StandaloneSubHeader from '../../components/StandaloneSubHeader'
  import SearchBar from '../../components/SearchBar.vue'
  import GalleryItemLocation from '../../components/gallery/GalleryItemLocation.vue'
  import GalleryItemVenue from '../../components/gallery/GalleryItemVenue.vue'
  import GalleryItemAlbum from '../../components/gallery/GalleryItemAlbum'
  import GalleryItemPlaceholder from '../../components/gallery/GalleryItemPlaceholder'
  import Pagination from '../../components/Pagination.vue'
  import SiteFooter from '../../components/SiteFooter.vue'

  export default {
    name: 'gallery-page-location',
    props: ['location'],
    components: {
      StandaloneSubHeader,
      SearchBar,
      GalleryItemLocation,
      GalleryItemVenue,
      GalleryItemAlbum,
      GalleryItemPlaceholder,
      Pagination,
      SiteFooter
    },
    data() {
      return {
        displayChildren: [],
        page: this.$route.query.page || 1,
        pages: Math.ceil( this.location.children_count / 24 ),
        loadingMore: false,
        loading: true
      }
    },
    computed: {
      apiChildrenURL() {
        if( this.location.id ) {
          return this.$root.apiURL + '/location/' + this.location.id + '/' + this.page
        } else {
          return this.$root.apiURL + '/structure'
        }
      },
      columnSizes() {
        if( this.$root.standalone && this.$store.state.persistent.listView ) {
          return 'full'
        } else {
          return 'width-1-3 width-1-2-medium width-1-2-small'
        }
      },
      topLevel() {
        return !this.location.parent || !this.location.parent.uri || parseInt( this.location.parent.id ) === 0
      }
    },
    mounted() {
      this.getChildren()
    },
    methods: {
      getChildren() {
        this.displayChildren = []
        this.loading = true
        FetchJSON( this.apiChildrenURL )
          .then( response => {
            if( response.children ) {
              this.displayChildren = response.children
            }
            this.loading = false
            // this.$root.setTitle( 'Galleries | ' + this.location.title )
            this.$root.setDescription( 'Places in ' + this.location.title )
          } )
          .catch( e => {
            console.error( e )
            this.loading = false
          } )
      },
      navigateToPage: function( i ) {
        this.page = i
        this.$router.push( { query: { page: this.page } } )
        // this.getChildren()
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../styles/env";

  .location-title {
    margin-bottom: 2rem;

    .standalone & {
      margin-bottom: 0;
    }
  }
</style>
