const getUserToken: () => Promise<string | null> = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = window.firebase.auth()
            .onAuthStateChanged((user: any) => {
                unsubscribe();
                if (user) {
                    user.getIdToken().then((idToken: string) => {
                        resolve(idToken);
                    }, (error: any) => {
                        resolve(null);
                    });
                } else {
                    resolve(null);
                }
            });
    });
};

export default getUserToken;
