<template>
  <div class="front-page flex-1 flexbox-vertical flexbox-justify-center flexbox-align-center">
    <div class="container">
      <main class="flex-1 container no-padding columns-on-tiny flexbox-align-center">
        <div class="column full">
          <img src="/images/logos/smileonthetiles-logo.svg" alt="Smileonthetiles" width="100" class="align-center-">
          <h1>Smileonthetiles</h1>
          <h2>Plymouth's committed and legendary premiere nightlife photography</h2>
          <div v-if="!$root.standalone">
            <search-bar :placeholder="'union rooms last sat'"></search-bar>
            <div class="hero-buttons">
              <router-link to="/galleries/plymouth" class="block large">Go to the Galleries</router-link>
            </div>
          </div>
        </div>
        <div class="column full"></div>
        <template v-if="stats">
          <div v-for="(stat, name) in stats" class="stat column flex-1-small width-1-2-tiny hide-on-tiny-">
            <strong v-text="stat.toLocaleString()"/>
            <br>
            <span v-text="name"/>
          </div>
        </template>
        <template v-else>
          <div class="stat column flex-1">
            <strong>&nbsp;</strong> <br/> <span>&nbsp;</span>
          </div>
        </template>
        <div class="column full"></div>
      </main>
    </div>
    <div class="photos hide-on-mobile" :style="{ transform: 'rotateY(' + photosRotate.y + 'deg) rotateX(' + photosRotate.x + 'deg)' }">
      <home-page-photo v-for="(photo, index) in photos" :key="index" :index="index" :photo="photo" :photos="photos.length"/>
      <!--<div class="photos-button-wrapper flexbox flexbox-align-center flexbox-justify-center">-->
      <!--<button class="block large photos-button">I can see me!</button>-->
      <!--</div>-->
    </div>
    <site-footer-mini/>
    <script type="application/ld+json">
      {
        "@context": "http://schema.org",
        "@type": "WebSite",
        "url": "https://smileonthetiles.co.uk",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://smileonthetiles.co.uk/search?q={search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }


    </script>
  </div>
</template>

<script>
  import FetchJSON from '../libs/FetchJSON.ts'
  import SearchBar from '../components/SearchBar.vue'
  import HomePagePhoto from '../components/HomePagePhoto.vue'
  import SiteFooterMini from '../components/SiteFooterMini.vue'
  import Meta from '../modules/Meta'

  export default {
    name: 'home',
    components: {
      SearchBar,
      HomePagePhoto,
      SiteFooterMini
    },
    data() {
      return {
        stats: null,
        photosLoaded: false,
        photos: [],
        photosRotate: {
          x: 0,
          y: 0
        }
      }
    },
    mounted() {
      this.loadStats()
      window.addEventListener( 'resize', this.checkWidthAndLoadPhotos )
      this.checkWidthAndLoadPhotos()
      window.addEventListener( 'mousemove', this.updatePhotoRotationMouse )
      window.addEventListener( 'deviceorientation', this.updatePhotoRotationAccelerometer )
      Meta.title( '' )
    },
    methods: {
      loadStats() {
        FetchJSON( this.$root.apiURL + '/stats', true )
          .then( response => {
            this.stats = response
          } )
      },
      checkWidthAndLoadPhotos() {
        if( window.innerWidth > 768 && !this.photosLoaded ) {
          this.loadPhotos()
        }
      },
      loadPhotos() {
        this.photosLoaded = true
        window.removeEventListener( 'resize', this.loadPhotos )
        FetchJSON( this.$root.apiURL + '/photos/random/' + Math.ceil( window.innerHeight / 40 ), true )
          .then( response => {
            this.photos = response
          } )
      },
      updatePhotoRotationMouse: function( e ) {
        this.photosRotate.x = ( ( window.innerHeight - e.y ) / window.innerHeight ) * 2
        this.photosRotate.y = -( ( window.innerWidth - e.x ) / window.innerWidth ) * 2
      },
      updatePhotoRotationAccelerometer: function( e ) {
        this.photosRotate.x = 55 - e.beta
        this.photosRotate.y = e.gamma
      }
    },
    beforeDestroy() {
      window.removeEventListener( 'resize', this.loadStats )
      window.removeEventListener( 'resize', this.loadPhotos )
      window.removeEventListener( 'mousemove', this.updatePhotoRotationMouse )
      window.removeEventListener( 'deviceorientation', this.updatePhotoRotationAccelerometer )
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";

  .front-page {
    position: relative;

    overflow: hidden;

    background: url("/images/backgrounds/home.jpg") center fixed;
    background-size: cover;

    perspective: 800px;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.35) 50%, rgba(0, 0, 0, 0.35) 100%);
      background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.35) 50%, rgba(0, 0, 0, 0.35) 100%);
      background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.35) 50%, rgba(0, 0, 0, 0.35) 100%);

      content: "";

      @media #{$media-mobile} {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  main {
    width: 100%;
    max-width: 68rem;
    margin: 0;
    padding-top: 10rem;
    padding-bottom: 10rem;

    position: relative;
    z-index: 3;

    color: #FFF;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);

    background: none;

    @media #{$media-mobile} {
      margin: 0 auto;

      text-align: center;
    }

    @media #{$media-tiny} {
      padding-top: 10rem;
      padding-bottom: 5rem;

      .standalone & {
        padding-top: 5rem;
      }
    }
  }

  img {
    @media #{$media-mobile} {
      margin: 0 auto;
    }
  }

  h1 {
    padding-top: 2rem;
    /*padding-bottom: 4rem;*/

    //color: $primary-colour;
    font-size: 8rem;
    letter-spacing: -0.2rem;

    @media #{$media-tiny} {
      font-size: 5rem;
    }
  }

  h2 {
    padding-top: 1rem;
    padding-bottom: 1rem;

    font-weight: 400;
  }

  hr {
    background-color: rgba(255, 255, 255, 0.25);
  }

  .search-form {
    margin-top: 3rem;
  }

  .hero-buttons {
    padding-top: 1rem;
    padding-bottom: 8rem;

    a {
      width: 100%;

      box-sizing: border-box;

      text-align: center;
    }
  }

  .stat {
    max-width: 20rem;
    padding-top: 3rem;

    @media #{$media-mobile} {
      max-width: none;

      text-align: center;
    }

    @media #{$media-tiny} {
      br {
        /*display: none;*/
      }
    }

    strong {
      font-size: 3rem;
    }

    span {
      /*font-size: 2.5rem;*/
    }
  }

  footer {
    position: relative;
    z-index: 3;
  }

  .photos {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    transform: rotateX(3deg) rotateY(3deg);
    /*transform-origin: 100% 50%;*/
    transform-origin: 50% 50%;
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }

  .photos-button-wrapper {
    width: 37.5vw;
    max-width: 450px;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;

    transform: translateZ(101px);

    &:hover {
      .photos-button {
        opacity: 1;
      }
    }
  }

  .photos-button {
    transition: opacity 200ms;

    opacity: 0;
  }
</style>
