import { render, staticRenderFns } from "./GalleryItemPlaceholder.vue?vue&type=template&id=e0101cae&scoped=true&"
import script from "./GalleryItemPlaceholder.vue?vue&type=script&lang=js&"
export * from "./GalleryItemPlaceholder.vue?vue&type=script&lang=js&"
import style0 from "./GalleryItemPlaceholder.vue?vue&type=style&index=0&id=e0101cae&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0101cae",
  null
  
)

export default component.exports