<template>
  <router-link :to="'/galleries' + album.uri" :data-label="isPrivate ? 'Private' : ''" :data-count="album.children_count.toLocaleString()" data-countunits="photos" class="card hoverable" :class="classes" itemscope itemtype="https://schema.org/ImageGallery">
    <img v-show="loaded" :src="album.image.url" :alt="album.title" class="card-image">
    <div v-if="!loaded" class="card-image">
      <div class="photo-loader aspect-4-3" :class="'animation-offset-' + offset"/>
    </div>
    <div class="card-content">
      <span class="hidden" itemprop="datePublished">{{ album.date }}</span>
      <span class="hidden" itemprop="primaryImageOfPage" itemscope itemtype="http://schema.org/ImageObject">
				<span itemprop="contentUrl">{{ album.image.url }}</span>
      </span>
      <h4 v-html="album.title" class="card-title" itemprop="name"></h4>
      <h5 v-if="showParent === true && album.parent.title" class="card-subtitle" itemprop="contentLocation">{{ album.parent.title }}</h5>
      <h5 v-else-if="parseInt( album.parent.id ) === 9" class="card-subtitle">{{ album.meta.pretty_date }}</h5>
    </div>
  </router-link>
</template>

<script>
  export default {
    name: 'gallery-item-album',
    props: ['album', 'offset', 'showParent', 'alwaysGrid', 'animateIn'],
    data() {
      return {
        loaded: false,
        isPrivate: this.album.meta.private === true
      }
    },
    computed: {
      classes() {
        let classes = {}
        if( this.animateIn === true ) {
          classes['animate-zoom-in'] = true
          classes['animation-offset-' + this.offset] = true
        }
        if( this.isPrivate ) {
          classes['label-red'] = true
        }
        if( this.$root.listView && this.alwaysGrid !== true ) {
          classes['list'] = true
        }
        return classes
      }
    },
    mounted() {
      /* global Image */
      let img = new Image()
      img.src = this.album.image.url
      img.onload = () => {
        this.loaded = true
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
