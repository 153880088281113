import getUserToken from '../firebase/UserToken';

const FetchPOST = (url: string, data: { [key: string]: any }) => {
    return getUserToken()
        .then((token) => new Promise((resolve, reject) => {
                if (token) {
                    data.jwt = token;
                }
                const request = new Request(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data',
                    },
                    body: JSON.stringify(data),
                });
                fetch(request)
                    .then((response) => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            return reject({
                                status: response.status,
                                response,
                            });
                        }
                    })
                    .then((json) => resolve(json))
                    .catch((e) => reject(e));
            }),
        );
};

export default FetchPOST;
