import Vue from 'vue';
import VueRouter, {RouterOptions} from 'vue-router';
import Routes from './Routes';
import Meta from '@/modules/Meta';
import Store from '@/modules/Store';

Vue.use(VueRouter);

const options: RouterOptions = {
    mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
    routes: Routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || {x: 0, y: 0};
    },
};

const Router: VueRouter = new VueRouter(options);

Router.beforeEach((to: any, from: any, next: any) => {
    if ( to.meta ) {
        if ( to.meta.title ) {
            Meta.title(to.meta.title);
        }
        Meta.description(to.meta.description || undefined);
    } else {
        Meta.description(undefined);
    }
    Meta.url('https://smileonthetiles.co.uk' + to.path);
    const currentUser = window.firebase.auth().currentUser;
    // const requiresAuth = to.matched.some((record: any) => record.meta.requiresAuth);
    // if (requiresAuth) {
    //     if (currentUser) {
            next();
    //     } else {
    //         // let requestedURI = to.matched.find( record => record.meta.requiresAuth ).path
    //         const requestedURI = to.fullPath;
    //         Store.commit('redirectAfterLogin', requestedURI);
    //         next('/sign-in');
    //     }
    // } else {
    //     next();
    // }
});

declare var ga: any;

Router.afterEach((to: any, from: any) => {
    try {
    if ( ga ) {
        ga('send', {
            hitType: 'pageview',
            page: to.fullPath,
        });
    }
    } catch (e) {
        // console.error(e);
    }
});

export default Router;
