<template>
  <div class="flex-1 flexbox-vertical" itemscope itemtype="https://schema.org/Organization" :data-databaseid="venue.id" :data-cachecreated="venue.cache_created">
    <header class="header">
      <div class="container flexbox-align-center columns-on-tiny">
        <div v-if="venue.title" class="column full">
          <h1 v-html="venue.title" itemprop="name" class="venue-title"></h1>
        </div>
        <div v-if="!$root.standalone" class="column flex-1">
          <router-link :to="'/galleries' + venue.parent.uri" class="backlink">
            <i class="material-icons">more</i> <span>Back to {{ venue.parent.title }}</span>
          </router-link>
        </div>
        <!--<div v-if="!$root.standalone && $root.messagingSupported" class="column">-->
          <!--<button v-on:click.prevent="toggleSubscription" :disabled="loadingSubscription" class="big-simple inverted subscription-button">-->
            <!--<i v-if="subscribed" class="material-icons">notifications_active</i>-->
            <!--<i v-else class="material-icons">notifications_none</i>-->
            <!--&lt;!&ndash;<i class="material-icons">notifications_off</i>&ndash;&gt;-->
          <!--</button>-->
        <!--</div>-->
        <div v-if="$root.canShare" class="column hide-on-standalone">
          <button v-on:click.prevent="share" class="big-simple inverted">
            <i class="material-icons">share</i>
          </button>
        </div>
        <div v-if="!$root.standalone" class="column full">
          <search-bar/>
        </div>
      </div>
    </header>
    <standalone-sub-header :backLink="'/galleries' + venue.parent.uri" :backLinkTitle="venue.parent.title" :shareTitle="venue.title" :shareText="'Check out ' + this.venue.title + ' on Smileonthetiles'" :shareURL="venue.url">
      <!--<div v-if="$root.messagingSupported" class="column buttons">-->
        <!--<button v-on:click.prevent="toggleSubscription" :disabled="loadingSubscription" class="button subscription-button">-->
          <!--<i v-if="subscribed" class="material-icons">notifications_active</i>-->
          <!--<i v-else class="material-icons">notifications_none</i>-->
          <!--&lt;!&ndash;<i class="material-icons">notifications_off</i>&ndash;&gt;-->
        <!--</button>-->
      <!--</div>-->
    </standalone-sub-header>
    <main class="flex-1">
      <div class="container flexbox-align-start">
        <div class="column flex-1 no-padding">
          <div class="container no-padding">
            <template v-if="loading">
              <template v-for="i in 12">
                <div class="column" :class="columnSizes">
                  <gallery-item-placeholder :offset="i" :animateIn="true"/>
                </div>
              </template>
            </template>
            <template v-else>
              <template v-for="(album, index) in displayChildren">
                <div v-if="album.children_count" class="column" :class="columnSizes" :key="index">
                  <gallery-item-album :album="album" :offset="index % album.children_per_call"/>
                </div>
              </template>
              <div v-if="pages > 1" class="column full">
                <pagination :currentPage="parseInt( page )" :pages="pages" :padding="2" v-on:navigateTo="navigateToPage"/>
              </div>
            </template>
          </div>
        </div>
        <!--<div v-if="!$root.standalone" class="info-pane column width-1-4 width-full-small hide-on-mobile">-->
        <!--<img :src="venue.image.url" :alt="venue.title">-->
        <!--<h2 v-html="venue.title"></h2>-->
        <!--<p v-html="venue.parent.title"></p>-->
        <!--</div>-->
      </div>
    </main>
    <site-footer/>
    <!--<script type="application/ld+json">
      {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "{{ venue.title }}"
      }
    </script>-->
  </div>
</template>

<script>
  import FetchJSON from '../../libs/FetchJSON.ts'
  import FetchPOST from '../../libs/FetchPOST.ts'
  import StandaloneSubHeader from '../../components/StandaloneSubHeader.vue'
  import SearchBar from '../../components/SearchBar.vue'
  import GalleryItemAlbum from '../../components/gallery/GalleryItemAlbum.vue'
  import GalleryItemPlaceholder from '../../components/gallery/GalleryItemPlaceholder'
  import Pagination from '../../components/Pagination.vue'
  import SiteFooter from '../../components/SiteFooter.vue'
  // import FirebaseMessaging from '../../firebase/Messaging.ts'

  export default {
    name: 'gallery-page-venue',
    props: ['venue'],
    components: {
      StandaloneSubHeader,
      SearchBar,
      GalleryItemAlbum,
      GalleryItemPlaceholder,
      Pagination,
      SiteFooter
    },
    data() {
      return {
        displayChildren: [],
        page: this.$route.query.page || 1,
        pages: Math.ceil( this.venue.children_count / 24 ),
        loadingMore: false,
        loading: true,
        loadingSubscription: false
      }
    },
    computed: {
      apiChildrenURL() {
        return this.$root.apiURL + '/venue/' + this.venue.id + '/' + this.page
      },
      columnSizes() {
        if( this.$root.standalone && this.$store.state.persistent.listView ) {
          return 'full'
        } else {
          return 'width-1-3 width-1-2-medium width-1-2-small'
        }
      },
      topicName() {
        return 'venue-' + this.venue.id
      },
      subscribed() {
        return this.$store.state.subscribedTopics.indexOf( this.topicName ) > -1
      }
    },
    mounted() {
      this.getChildren()
    },
    methods: {
      getChildren() {
        this.displayChildren = []
        this.loading = true
        FetchJSON( this.apiChildrenURL )
          .then( response => {
            if( response.children ) {
              this.displayChildren = response.children
            }
            this.loading = false
            // this.$root.setTitle( 'Galleries | ' + this.venue.parent.title + ' | ' + this.venue.title )
            this.$root.setDescription( 'Photos from ' + this.venue.title )
          } )
          .catch( e => {
            console.error( e )
            this.loading = false
          } )
      },
      navigateToPage: function( i ) {
        this.page = i
        this.$router.push( { query: { page: this.page } } )
      },
      share() {
        navigator
          .share( {
            title: this.venue.title,
            text: 'Check out ' + this.venue.title + ' on Smileonthetiles',
            url: this.venue.url
          } )
          .then( () => {
            // console.log( 'Successful share' )
          } )
          .catch( error => {
            console.error( 'Error sharing', error )
          } )
      },
      toggleSubscription() {
        this.loadingSubscription = true;
        const topicName = 'venue-' + this.venue.id
        let apiURL = this.$root.apiURL + '/topics/' + ( this.subscribed ? 'unsubscribe' : 'subscribe' ) + '/' + topicName;

        // FirebaseMessaging.getToken()
        //   .then( ( token ) => {
        //     FetchPOST(apiURL, {token})
        //       .then( (response)  => {
        //         console.log( response )
        //         if ( response.success ) {
        //           if( this.subscribed ) {
        //             this.$store.commit( 'removeTopicSubscription', this.topicName );
        //           } else {
        //             this.$store.commit( 'addTopicSubscription', this.topicName );
        //           }
        //         }
        //         this.loadingSubscription = false;
        //       })
        //       .catch( e => {
        //         console.error( e )
        //         this.loadingSubscription = false;
        //       });
        //   })
        //   .catch( e => {
        //     console.error( e )
        //     this.loadingSubscription = false;
        //   });
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../styles/env";

  .venue-title {
    margin-bottom: 2rem;

    .standalone & {
      margin-bottom: 0;
    }
  }

  .subscription-button {
    &:disabled {
      opacity: 0.5;
    }
  }
</style>
