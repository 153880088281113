<template>
  <div class="page flex-1 flexbox-vertical">
    <header class="header">
      <div class="container flexbox-align-center columns-on-tiny">
        <div class="column flex-1">
          <h1>My Smiles</h1>
        </div>
        <div class="column">
          <i class="material-icons">tag_faces</i>
        </div>
      </div>
    </header>
    <main class="flex-1">
      <div class="container favourites">
        <div v-if="loading" class="column">
          <p>Loading smiles&hellip;</p>
        </div>
        <template v-else>
          <template v-if="favouriteIDs.length">
            <template v-for="(favourite, index) in favourites">
              <div v-if="favourite" :key="index" class="column width-1-3 width-1-2-medium width-full-mobile">
                <div class="item-wrapper">
                  <div class="card">
                    <img :src="favourite.image.url" class="card-image">
                    <div class="card-content">
                      <h4 v-html="favourite.meta.album.title" class="card-title"></h4>
                      <span v-html="favourite.meta.album.venue.title" class="card-meta"></span>
                    </div>
                  </div>
                  <div class="links flexbox flexbox-justify-stretch flexbox-align-stretch">
                    <a :href="favourite.image.url" class="block" :download="'Smileonthetiles-' + favourite.id + '-' + favourite.meta.date + '.jpg'"><i class="material-icons">cloud_download</i> Download</a>
                    <template v-if="$root.canShare">
                      <div class="divider"/>
                      <button class="block" v-on:click.prevent="share( favourite.url )">
                        <i class="material-icons">share</i> Share
                      </button>
                    </template>
                    <div class="divider"/>
                    <router-link :to="'/galleries' + favourite.meta.album.uri" class="block">
                      <i class="material-icons">photo_album</i> Album
                    </router-link>
                    <div class="divider"/>
                    <button class="block" v-on:click.prevent="remove( favourite.id )">
                      <i class="material-icons">delete</i> Remove
                    </button>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <div v-else class="column width-1-4 width-1-3-medium width-1-2-mobile">
            <p>You don't have any favourites :'(</p>
          </div>
        </template>
      </div>
    </main>
    <site-footer/>
  </div>
</template>

<script>
  import FetchJSON from '../libs/FetchJSON.ts'
  import GalleryItemPhoto from '../components/gallery/GalleryItemPhoto.vue'
  import SiteFooter from '../components/SiteFooter.vue'

  export default {
    name: 'my-smiles',
    components: {
      GalleryItemPhoto,
      SiteFooter
    },
    data() {
      return {
        loading: true,
        favourites: []
      }
    },
    computed: {
      favouriteIDs() {
        return this.$store.state.favourites
      }
    },
    mounted() {
      if( this.favouriteIDs.length ) {
        this.getFavouritePhotos()
      } else {
        this.loaded = true
      }
    },
    methods: {
      getFavouritePhotos() {
        this.loading = false
        this.favourites = Array( this.favouriteIDs.length )
        for( let i in this.favouriteIDs ) {
          if( this.favouriteIDs.hasOwnProperty( i ) ) {
            FetchJSON( this.$root.apiURL + '/photo/' + this.favouriteIDs[i] )
              .then( response => {
                this.favourites.splice( i, 1, response )
              } )
              .catch( error => {
                console.error( error )
                this.loading = false
              } )
          }
        }
      },
      remove( id ) {
        /* global confirm */
        if( confirm( 'Are you sure you want to remove this photo from your favourites?' ) ) {
          this.$store.commit( 'unfavourite', id )
        }
      },
      share( url ) {
        navigator.share( {
            title: 'One of my favourites',
            text: 'Check out this photo on Smileonthetiles',
            url: url
          } )
          .then( () => {
            // console.log( 'Successful share' )
          } )
          .catch( error => {
            console.log( 'Error sharing', error )
          } )
      }
    },
    watch: {
      favouriteIDs() {
        this.getFavouritePhotos()
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";

  .favourites {
    .column {
      margin-bottom: 5rem;
    }
  }

  .item-wrapper {
    overflow: hidden;

    border-radius: $border-radius;

    @include shadow-2;
  }

  .card {
    margin-bottom: 0;

    border-radius: 0;

    z-index: 1;
  }

  .links {
    position: relative;
    z-index: 2;

    //background-color: $primary-colour;
    background-color: #FFF;

    border-top: 1px rgba($base-font-colour, 0.1) solid;

    border-radius: 0 0 $border-radius $border-radius;

    a,
    button {
      /*width: 100%;*/
      margin: 0;
      padding: 1.5rem 2rem 1rem;

      @include flex(1);

      color: $base-font-colour;
      text-align: center;
      font-size: 1.75rem;

      background-color: transparent;

      border-radius: 0;

      box-shadow: none;

      .material-icons {
        margin-bottom: 0.5rem;

        display: block;

        color: $primary-colour;

        transition: inherit;
      }

      &:hover {
        color: #FFF;

        .material-icons {
          color: inherit;
        }
      }
    }

    .divider {
      width: 1px;

      background-color: rgba($base-font-colour, 0.1);
    }
  }
</style>
