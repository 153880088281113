<template>
  <div id="photoswipe-wrapper" class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="pswp__bg"></div>
    <div class="pswp__scroll-wrap">
      <!-- Container that holds slides.
        PhotoSwipe keeps only 3 of them in the DOM to save memory.
        Don't modify these 3 pswp__item elements, data is added later on. -->
      <div class="pswp__container">
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
      </div>
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <div class="pswp__counter"></div>
          <button class="pswp__button pswp__button--close material-icons" title="Close (Esc)">close</button>
          <!-- <button v-on:touchstart.stop v-on:click="share" class="pswp__button" title="Share">
            <i class="material-icons">share</i>
          </button> -->
          <!-- <button v-on:touchstart.stop v-on:click="report" class="pswp__button" title="Report photo">
            <i class="material-icons">report_problem</i>
          </button> -->
          <button v-on:touchstart.stop v-on:click="favorite" class="pswp__button" title="Favourite">
            <i v-if="currentItemIsFavourite" class="material-icons red-heart">favorite</i>
            <i v-else class="material-icons">favorite_border</i>
          </button>
          <a :href="currentItem.src" target="_blank" class="pswp__button" title="Download" :download="'Smileonthetiles-' + $parent.album.title + '-' + currentItem.db_id + '-' + currentItem.date + '.jpg'">
            <i class="material-icons">cloud_download</i> </a>
          <button class="pswp__button pswp__button--fs hide-on-pswp-fs material-icons" title="Fullscreen">fullscreen</button>
          <button class="pswp__button pswp__button--fs hide-on-pswp-no-fs material-icons" title="Exit fullscreen">fullscreen_exit</button>
          <button class="pswp__button pswp__button--zoom material-icons" title="Zoom in/out">zoom_in</button>
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
          <div class="pswp__share-tooltip"></div>
        </div>
        <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button>
        <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button>
        <div class="pswp__caption">
          <div class="pswp__caption__center"></div>
        </div>
      </div>
    </div>
    <div v-if="sharing" v-on:click="sharing = false" class="share-wrapper flexbox flexbox-align-center flexbox-justify-center">
      <div v-on:click.stop class="share">
        <a :href="'https://www.facebook.com/dialog/share?app_id=1034584839972494&display=popup&href=' + currentItem.share_src" target="_blank" rel="noopener" class="block large facebook"><i class="fab fa-facebook fa-fw"></i> Share to Facebook</a>
        <a :href="'fb-messenger://share/?link=' + currentItem.share_src" rel="noopener" class="block large facebook hide-on-large hide-on-medium"><i class="fab fa-facebook-messenger fa-fw"></i> Share with Messenger</a>
        <a :href="'https://twitter.com/intent/tweet?url=' + currentItem.share_src" target="_blank" rel="noopener" class="block large twitter"><i class="fab fa-twitter fa-fw"></i> Share to Twitter</a>
        <!--<a :href="'http://tumblr.com/widgets/share/tool?canonicalUrl=' + currentItem.share_src" target="_blank" rel="noopener" class="block large tumblr"><i class="fab fa-tumblr fa-fw"></i> Share to Tumblr</a>-->
        <a :href="'mailto:?subject=Smileonthetiles%20photo%20-%20' + $parent.album.parent.title + '%20' + $parent.album.title + '%20(' + currentItem.date + ')&body=' + currentItem.share_src" target="_blank" rel="noopener" class="block large email">Share via email</a>
      </div>
    </div>
    <gallery-item-photo-report :key="currentItem.db_id" :photo="currentItem" v-on:hide="reporting = false" :class="{ show: reporting }"></gallery-item-photo-report>
  </div>
</template>

<script>
  import GalleryItemPhotoReport from './GalleryItemPhotoReport.vue'

  export default {
    name: 'photo-swipe',
    components: {
      GalleryItemPhotoReport
    },
    props: ['gallery'],
    data() {
      return {
        sharing: false,
        reporting: false,
        currentItem: {},
        currentItemIsFavourite: false
      }
    },
    methods: {
      addEventListeners() {
        this.getCurrentItem()
        this.gallery.photoswipe.listen( 'afterChange', this.getCurrentItem )
        this.gallery.photoswipe.listen( 'imageLoadComplete', this.getCurrentItem )
      },
      checkFavourite() {
        this.currentItemIsFavourite = this.$store.state.favourites.indexOf( parseInt( this.currentItem.db_id ) ) > -1
      },
      getCurrentItem() {
        this.currentItem = this.gallery.photoswipe.currItem
        this.checkFavourite()
      },
      share() {
        this.getCurrentItem()
        if( this.$root.canShare ) {
          navigator.share( {
              title: this.$parent.album.title,
              text: 'Check out this photo on Smileonthetiles',
              url: this.currentItem.share_src
            } )
            .catch( error => {
              console.log( 'Error sharing', error )
            } )
        } else {
          this.sharing = true
        }
      },
      report() {
        this.getCurrentItem()
        this.reporting = true
      },
      favorite() {
        if( this.currentItemIsFavourite ) {
          this.$store.commit( 'unfavourite', this.currentItem.db_id )
          this.currentItemIsFavourite = false
        } else {
          this.$store.commit( 'favourite', this.currentItem.db_id )
          this.currentItemIsFavourite = true

          if( this.$store.state.favourites.length < 2 ) {
            this.$store.commit( 'notify', {
              text: 'Photo added to your favourites',
              actions: {
                'My Smiles': '/my-smiles'
              }
            } )
          } else {
            this.$store.commit( 'notify', {
              text: 'Photo added to your favourites',
              lifespan: 3000
            } )
          }
        }
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../styles/env";

  .share-wrapper {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    overflow: hidden;

    background-color: rgba(0, 0, 0, 0.85)
  }

  .share {
    max-width: 100%;
    /*width: 500px;*/
    max-height: 100%;
    /*height: 500px;*/

    /*background-color: #FFF;*/

    a {
      margin: 3rem;

      display: block;

      &.facebook {
        background-color: $facebook;
      }

      &.twitter {
        background-color: $twitter;
      }

      &.email {
        background-color: #424242;
      }

      i {
        margin-right: 3rem;
      }
    }
  }

  .red-heart {
    width: 100%;

    position: relative;

    color: $favourite-colour;

    &::before {
      width: 14rem;
      height: 14rem;
      margin: -7rem 0 0 -7rem;

      position: absolute;
      top: 50%;
      left: 50%;

      background-color: $favourite-colour;

      border-radius: 100%;

      transform: scale(0);

      animation: echo ease-out 600ms;

      opacity: 0;

      content: "";
    }
  }

  .favourite-identifier {
    width: 15rem;
    height: 15rem;

    position: fixed;
    top: 50%;
    left: 50%;

    color: $favourite-colour;
    font-size: 15rem;

    transform: translateX(-50%) translateY(-50%);

    .material-icons {
      display: block;

      font-size: inherit;
    }
  }

  .pswp__caption__center {
    text-align: center;
  }
</style>
