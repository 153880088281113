import NumberSuffix from './NumberSuffix';
import {padLeft} from './Strings';

const DayNames: Array<{ long: string; short: string }> = [
    {
        long: 'Sunday',
        short: 'Sun',
    },
    {
        long: 'Monday',
        short: 'Mon',
    },
    {
        long: 'Tuesday',
        short: 'Tue',
    },
    {
        long: 'Wednesday',
        short: 'Wed',
    },
    {
        long: 'Thursday',
        short: 'Thu',
    },
    {
        long: 'Friday',
        short: 'Fri',
    },
    {
        long: 'Saturday',
        short: 'Sat',
    },
];

const MonthNames: { [key: string]: { long: string; short: string } } = {
    1: {
        long: 'January',
        short: 'Jan',
    },
    2: {
        long: 'February',
        short: 'Feb',
    },
    3: {
        long: 'March',
        short: 'Mar',
    },
    4: {
        long: 'April',
        short: 'Apr',
    },
    5: {
        long: 'May',
        short: 'May',
    },
    6: {
        long: 'June',
        short: 'Jun',
    },
    7: {
        long: 'July',
        short: 'Jul',
    },
    8: {
        long: 'August',
        short: 'Aug',
    },
    9: {
        long: 'September',
        short: 'Sep',
    },
    10: {
        long: 'October',
        short: 'Oct',
    },
    11: {
        long: 'November',
        short: 'Nov',
    },
    12: {
        long: 'December',
        short: 'Dec',
    },
};

export default function PrettyDate(
    strFormat: string = '',
    unixTime: number = 0,
): string {
    const datThisDate: Date = unixTime ? new Date(unixTime * 1000) : new Date();

    const objTime: { [key: string]: string | number } = {};
    objTime.N = datThisDate.getDay() % 7;
    objTime.j = datThisDate.getDate();
    objTime.n = datThisDate.getMonth() + 1;
    objTime.Y = datThisDate.getFullYear();
    objTime.G = datThisDate.getHours();
    objTime.i = padLeft(datThisDate.getMinutes().toString(), 2, '0');
    objTime.s = padLeft(datThisDate.getSeconds().toString(), 2, '0');
    objTime.u = datThisDate.getMilliseconds();
    objTime.U = Math.floor(datThisDate.getTime() / 1000);
    objTime.Z = datThisDate.getTimezoneOffset() * 3600;
    objTime.D = DayNames[objTime.N].short;
    objTime.l = DayNames[objTime.N].long;
    objTime.d = padLeft(objTime.j.toString(), 2, '0');
    objTime.S = NumberSuffix(objTime.j);
    objTime.m = padLeft(objTime.n.toString(), 2, '0');
    objTime.F = MonthNames[objTime.n].long;
    objTime.M = MonthNames[objTime.n].short;
    objTime.y = padLeft(objTime.Y.toString(), 2);
    objTime.H = padLeft(objTime.G.toString(), 2, '0');
    objTime.g =
        objTime.G === 0 ? 12 : objTime.G > 12 ? objTime.G - 12 : objTime.G;
    objTime.h = padLeft(objTime.g.toString(), 2, '0');
    objTime.a = objTime.G < 12 ? 'am' : 'pm';
    objTime.A = objTime.a.toUpperCase();
    objTime.L =
        objTime.Y % 4 === 0 &&
        ((objTime.Y % 100 === 0 && objTime.Y % 400 === 0) || objTime.Y % 100 !== 0)
            ? 1
            : 0;
    let strOut = '';
    let intChar = 0;
    while (intChar < strFormat.length) {
        const strChar = strFormat.charAt(intChar);
        if (strChar === '\\') {
            intChar++;
            strOut += strFormat.substr(intChar, 1);
        } else if (strChar === 'r') {
            strOut +=
                objTime.D +
                ', ' +
                objTime.j +
                ' ' +
                objTime.M +
                ' ' +
                objTime.Y +
                ' ' +
                objTime.H +
                ':' +
                objTime.i +
                ':' +
                objTime.s;
        } else if (strChar === 'x') {
            strOut +=
                objTime.Y +
                '-' +
                objTime.m +
                '-' +
                objTime.d +
                '\\T' +
                objTime.H +
                ':' +
                objTime.i +
                ':' +
                objTime.s +
                '\\Z';
        } else {
            if (strChar !== '' && objTime.hasOwnProperty(strChar)) {
                strOut += objTime[strChar];
            } else {
                strOut += strChar;
            }
        }
        intChar++;
    }
    return strOut;
}
