<template>
  <div class="flex-1 flexbox-vertical" :data-databaseid="album.id" :data-cachecreated="album.cache_created">
    <header class="header">
      <div class="container flexbox-align-center columns-on-tiny">
        <div v-if="album.title" class="column full album-title-wrapper">
          <h1 v-html="album.title" class="album-title"/>
          <h2 v-if="albumParentTitle" v-html="albumParentTitle" class="album-parent-title"/>
          <p v-if="album.meta.description" v-html="album.meta.description" class="album-description"/>
        </div>
        <div v-if="!$root.standalone && album.parent && album.parent.uri && album.parent.title" class="column flex-1">
          <router-link :to="'/galleries' + album.parent.uri" class="backlink">
            <i class="material-icons">more</i> <span>Back to {{ album.parent.title }}</span>
          </router-link>
        </div>
        <div v-if="$root.canShare" class="column hide-on-standalone">
          <button v-on:click.prevent="share" class="big-simple inverted">
            <i class="material-icons">share</i>
          </button>
        </div>
        <div v-if="!$root.standalone" class="column full">
          <search-bar/>
        </div>
      </div>
    </header>
    <standalone-sub-header :backLink="'/galleries' + album.parent.uri" :backLinkTitle="album.parent.title" :hideViews="true" :shareTitle="album.title" :shareText="'Check out this album on Smileonthetiles'" :shareURL="album.url">
      <!--<div class="column buttons">-->
        <!--<button v-on:click.prevent="" class="button">-->
          <!--<i class="material-icons">bookmark_border</i>-->
          <!--<i class="material-icons">bookmark</i>-->
        <!--</button>-->
      <!--</div>-->
    </standalone-sub-header>
    <album-access-management v-if="album && album.meta && album.meta.private && album.access && ( album.access.admin || album.access.owner )" :albumID="album.id"/>
    <main class="flex-1">
      <div v-if="loading" class="container cosy columns-on-tiny">
        <div v-for="i in 24" class="gallery-photo animate-zoom-in column width-1-4 width-1-3-medium width-1-2-mobile" :class="'animation-offset-' + (i % 24)">
          <div class="photo-loader aspect-3-2" :class="'animation-offset-' + (i % 24)"/>
        </div>
      </div>
      <locked-album v-else-if="locked" :album="album"/>
      <div v-else-if="error" class="container">
        <div class="column">
          <div class="large-text">
            <h1 v-html="error.status"/>
            <p v-html="error.statusText"/>
          </div>
        </div>
      </div>
      <div v-else class="container cosy columns-on-tiny">
        <template v-for="(photo, index) in displayChildren">
          <div v-if="separateDate && ( index === 0 || photo.date !== displayChildren[index - 1].date )" class="column full">
            <h3 class="date-separator">{{ PrettyDate( 'l, jS F Y', new Date( photo.date ) / 1000 ) }}</h3>
          </div>
          <div class="gallery-photo column width-1-4 width-1-3-medium width-1-2-mobile" :key="index">
            <gallery-item-photo :index="index" :photo="photo" :offset="index % 24" v-on:openGallery="openGallery( index )" :animateIn="index >= 24"/>
          </div>
          <!-- <div v-if="(index + 1) % 24 === 0" class="column full">
            <img class="advert" :src="`https://smileonthetiles.co.uk/a?${index}`"/>
          </div> -->
        </template>
        <div v-if="!allLoaded" class="loading-pad"></div>
      </div>
    </main>
    <photo-swipe ref="photoswipe" :gallery="gallery"/>
  </div>
</template>

<script>
  import Vue from 'vue'
  import FetchJSON from '../../libs/FetchJSON'
  import SearchBar from '../../components/SearchBar.vue'
  import GalleryItemPhoto from '../../components/gallery/GalleryItemPhoto.vue'
  import PhotoSwipe from '../../components/gallery/PhotoSwipe.vue'
  // import firebase from 'firebase'
  import Gallery from '../../libs/Gallery'
  import StandaloneSubHeader from '../../components/StandaloneSubHeader'
  import PrettyDate from '../../libs/PrettyDate'
  import AlbumAccessManagement from '../../components/gallery/AlbumAccessManagement'
  import LockedAlbum from '../../components/gallery/LockedAlbum'

  export default {
    name: 'gallery-page-album',
    props: ['album'],
    components: {
      LockedAlbum,
      AlbumAccessManagement,
      StandaloneSubHeader,
      SearchBar,
      GalleryItemPhoto,
      PhotoSwipe
    },
    data() {
      return {
        gallery: null,
        loading: true,
        locked: false,
        children: [],
        displayChildren: [],
        loadingMore: false,
        verticalBlankSpaceBeforeReload: 150,
        error: null
      }
    },
    computed: {
      apiChildrenURL() {
        return this.$root.apiURL + '/gallery/' + this.album.id
      },
      apiRequestAccessURL() {
        return this.$root.apiURL + '/access/' + this.album.id + '/request'
      },
      allLoaded() {
        return this.displayChildren.length >= this.children.length
      },
      albumParentTitle() {
        if( this.album.title !== this.album.meta.pretty_date ) {
          return this.album.meta.pretty_date
        } else if( !this.$root.standalone ) {
          return this.album.parent.title
        }
      },
      separateDate() {
        return this.album.id === 'favourites'
      }
    },
    beforeCreate() {
      /* global firebase */
      if( !firebase.auth().currentUser ) {
        this.$store.commit( 'redirectAfterLogin', this.$route.fullPath )
        this.$router.replace( '/sign-in' )
      }
    },
    mounted() {
      this.getChildren()
    },
    methods: {
      getChildren() {
        FetchJSON( this.apiChildrenURL )
          .then( response => {
            this.children = response
            this.loading = false
            this.showMore()
            this.addEventListeners()
            // this.$root.setTitle( 'Galleries | ' + this.album.parent.title + ' | ' + this.album.title )
            this.$root.setDescription( 'Album from ' + this.album.parent.title )
            this.gallery = new Gallery( this.album.id, this.children )
            Vue.nextTick()
              .then( () => {
                if( Gallery.canAutoOpen() ) {
                  this.openGallery( Gallery.getHashData().pid - 1 )
                }
              } )
          } )
          .catch( response => {
            if( parseInt( response.status ) === 401 ) {
              this.locked = true
            } else {
              this.error = {
                status: response.status,
                statusText: response.statusText
              }
            }
            this.loading = false
          } )
      },
      showMore() {
        this.loadingMore = true
        let start = this.displayChildren.length
        for( let i = 0; i < this.album.children_per_call; i++ ) {
          let add = this.children[start + i]
          if( add ) {
            this.displayChildren.push( add )
          }
        }
        this.loadingMore = false
        setTimeout( this.checkAtBottom, 250 )
      },
      openGallery: function( index ) {
        this.gallery.init( index )
        this.gallery.photoswipe.listen( 'ready', this.$refs.photoswipe.addEventListeners )
        this.gallery.open()
      },
      checkAtBottom() {
        if( this.allLoaded ) {
          this.removeEventListeners()
        } else if( ( window.innerHeight + window.scrollY ) >= document.body.offsetHeight - this.verticalBlankSpaceBeforeReload ) {
          this.showMore()
        }
      },
      addEventListeners() {
        document.addEventListener( 'scroll', this.checkAtBottom )
        window.addEventListener( 'resize', this.checkAtBottom )
      },
      removeEventListeners() {
        document.removeEventListener( 'scroll', this.checkAtBottom )
        window.removeEventListener( 'resize', this.checkAtBottom )
      },
      share() {
        navigator.share( {
            title: this.album.title,
            text: 'Check out this album on Smileonthetiles',
            url: this.album.url
          } )
          .catch( error => {
            console.error( 'Error sharing', error )
          } )
      },
      requestAccess() {
        //https://api.smileonthetiles.co.uk/access/albumID/request
      },
      PrettyDate
    },
    beforeDestroy() {
      this.removeEventListeners()
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../styles/env";

  .album-title-wrapper {
    margin-bottom: 2rem;

    .standalone & {
      margin-bottom: 0;
    }
  }

  .album-title {
    .standalone & {
      padding-bottom: 0;
    }
  }

  .album-parent-title {
    color: rgba(255, 255, 255, 0.75);
    font-size: 3rem;
    font-weight: 400;
  }

  main {
    transition: padding-top 200ms;

    @media (max-width: #{$largest-width}) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .error {
    //color: #FFF;
  }

  .date-separator {
    margin-top: 1rem;
    margin-bottom: 1rem;

    font-size: 2rem;
    text-align: right;

    border-bottom: 1px $base-font-colour solid;
  }

  .gallery-photo {
    margin-bottom: $gutter / 4;

    @include flexbox;
    justify-content: center;
    align-items: center;
  }

  .photo-placeholder {
    &:before {
      padding-bottom: (2 / 3) * 100%;

      display: block;

      background: linear-gradient(-45deg, #EEE, #BCBCBC, #EEE);
      background-size: 400% 400%;

      animation: slide-background ease 1.2s infinite;

      content: "";
    }

    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        &:before {
          animation-delay: ($i - 1) * 100ms;
        }
      }
    }
  }

  .advert {
    width: 100%;
    margin-bottom: 1rem;
  }

  .loading-pad {
    width: 100%;
    height: 200px;

    display: block;
  }
</style>
