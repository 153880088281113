export default class Meta {
    public static title(title: string | null = null) {
        title = (title ? title + ' | ' : '') + 'Smileonthetiles';
        window.history.replaceState(window.history.state, title);
        document.title = title;
        Meta.updateMetaContent('title', title);
    }

    public static description(description: string =
                                  'Plymouth\'s committed and legendary premiere nightlife photography') {
        Meta.updateMetaContent('description', description);
    }

    public static url(url: string = 'https://smileonthetiles.co.uk') {
        Meta.updateMetaContent('url', url);
    }

    private static updateMetaContent(property: string, value: any) {
        if (document.head) {
            const nodes: NodeListOf<HTMLElement> = document.head.querySelectorAll('[data-meta="' + property + '"]');
            for (const i of nodes) {
                i.setAttribute('content', value);
            }
        }
    }
}
