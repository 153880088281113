<template>
  <nav class="bottom-nav">
    <div class="bottom-nav-items flexbox flexbox-align-stretch">
      <router-link to="/galleries/plymouth" v-on:click.native="moreMenu = false" class="flex-1 align-center">
        <i class="material-icons">photo_library</i>
        <span>Galleries</span>
      </router-link>
      <div class="divider"/>
      <router-link to="/search" v-on:click.native="moreMenu = false" class="flex-1 align-center">
        <i class="material-icons selected-colour-highlight-2">search</i>
        <span>Search</span>
      </router-link>
      <div class="divider"/>
      <router-link to="/my-smiles" v-on:click.native="moreMenu = false" class="flex-1 align-center">
        <i class="material-icons selected-colour-red">favorite</i>
        <span>My Smiles</span>
      </router-link>
      <div class="divider"/>
      <button v-on:click="moreMenu = !moreMenu" class="flex-1 align-center" :class="{ active: moreMenu }">
        <i class="material-icons">more_vert</i>
        <span>More</span>
      </button>
    </div>
    <div :class="{ show: moreMenu }" class="more-menu">
      <!--<router-link v-on:click.native="moreMenu = false" to="/">-->
      <!--&lt;!&ndash;<i class="material-icons">home</i>&ndash;&gt;-->
      <!--Home-->
      <!--</router-link>-->
      <!--<router-link v-on:click.native="moreMenu = false" to="/galleries/favourites">-->
        <!--Sott Favourites-->
      <!--</router-link>-->
      <router-link v-on:click.native="moreMenu = false" to="/about">
        About
      </router-link>
      <router-link v-on:click.native="moreMenu = false" to="/contact">
        Contact
      </router-link>
      <router-link v-on:click.native="moreMenu = false" v-if="$store.state.user.loggedIn" to="/sign-out">
        Sign out
        <i class="material-icons">exit_to_app</i>
      </router-link>
      <router-link v-on:click.native="moreMenu = false" v-else to="/sign-in">
        Sign in
      </router-link>
      <hr>
      <div class="flexbox align-center">
        <a href="https://www.facebook.com/smileonthetilesplymouth/" target="_blank" rel="noopener" title="See our Facebook page" class="small flex-1">
          <i class="fab fa-facebook fa-fw"></i>
          <span class="hide-on-mobile">Facebook</span>
        </a>
        <a href="https://www.instagram.com/smileonthetiles/" target="_blank" rel="noopener" title="See our Instagram feed" class="small flex-1">
          <i class="fab fa-instagram fa-fw"></i>
          <span class="hide-on-mobile">Instagram</span>
        </a>
        <a href="https://twitter.com/Smileonthetiles" target="_blank" rel="noopener" title="Read our Tweets" class="small flex-1">
          <i class="fab fa-twitter fa-fw"></i>
          <span class="hide-on-mobile">Twitter</span>
        </a>
        <a href="skype:smileonthetiles?call" title="Call us on Skype" target="_blank" rel="noopener" class="small flex-1">
          <i class="fab fa-skype fa-fw"></i>
          <span class="hide-on-mobile">Skype</span>
        </a>
      </div>
      <!--<hr>-->
      <!--<div class="flexbox">-->
      <!--<router-link v-on:click.native="moreMenu = false" to="/terms" class="small flex-1">Terms and Conditions</router-link>-->
      <!--<router-link v-on:click.native="moreMenu = false" to="/privacy" class="small flex-1">Privacy Policy</router-link>-->
      <!---->
      <!--</div>-->
    </div>
    <div :class="{ show: moreMenu }" v-on:click="moreMenu = false" class="more-menu-mask"/>
  </nav>
</template>

<script>
  export default {
    name: 'navigation-bar-bottom',
    data() {
      return {
        moreMenu: false
      }
    },
    methods: {
      closeSidebar() {
        this.showSidebar = false
      },
      closeDropdown() {
        this.showDropdown = false
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";

  .bottom-nav {
    position: sticky;
    bottom: -1px;
    z-index: 11;

    .divider {
      width: 1px;

      background-color: rgba(#FFF, 0.1);
    }
  }

  .bottom-nav-items {
    position: relative;
    z-index: 3;

    background-color: #212121;

    @include shadow-8;

    a,
    button {
      padding: 2rem;

      color: #FFF;
      text-decoration: none;
      line-height: 1;

      @media #{$media-mobile} {
        padding: 1.25rem 0.75rem;
      }

      .material-icons {
        margin-right: 2rem;

        display: inline-block;

        color: $primary-colour-light;

        @media #{$media-mobile} {
          margin-right: 0;
          margin-bottom: 0.5rem;

          display: block;

          font-size: 2.5rem;
        }
      }

      span {
        font-size: 2rem;

        @media #{$media-mobile} {
          font-size: 1.5rem;
        }
      }

      &:hover {
        color: #FFF;
      }

      &.active,
      &.router-link-active {
        color: $base-font-colour;

        //@include primary-grad-background;
        background-color: #FFF;

        .material-icons {
          color: $primary-colour-dark;

          &.selected-colour-red {
            color: $favourite-colour;
          }

          &.selected-colour-highlight-1 {
            color: $primary-highlight-1;
          }

          &.selected-colour-highlight-2 {
            color: $primary-highlight-2;
          }
        }
      }
    }
  }

  .more-menu {
    max-height: 0;
    padding: 1rem 2rem 2rem;

    position: absolute;
    right: 0;
    bottom: 100%;
    left: 0;
    z-index: 2;

    overflow: hidden;

    background: #fff;
    // background: $primary-colour;
    // background: -moz-linear-gradient(top, $primary-colour 0%, $primary-colour-dark 100% );
    // background: -webkit-linear-gradient(top, $primary-colour 0%, $primary-colour-dark 100% );
    // background: linear-gradient(to bottom, $primary-colour 0%, $primary-colour-dark 100% );

    @include shadow-4;

    transform: translateY(100%);

    transition: max-height 400ms, transform 400ms, opacity 400ms;

    opacity: 0;

    &.show {
      max-height: 100vh;

      transform: translateY(0);

      opacity: 1;
    }

    a {
      padding: 2rem;

      display: block;

      color: $base-font-colour;
      text-decoration: none;

      &:not(.small) + a {
        border-top: 1px rgba(#000, 0.1) solid;
      }

      .material-icons {
        margin-left: 1rem;
      }

      .fab {
        margin-right: 1rem;

        font-size: 2.5rem;
        vertical-align: middle;

        &.fa-facebook {
          color: $facebook;
        }

        &.fa-twitter {
          color: $twitter;
        }

        &.fa-instagram {
          background: -webkit-linear-gradient(#feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        &.fa-skype {
          color: $skype;
        }

        @media #{$media-mobile} {
          margin: 0 auto;

          display: block;
        }
      }

      &.small {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;

        /*font-size: 1.75rem;*/
      }
    }

    hr {
      margin-top: 0;
      margin-bottom: 1rem;

      background-color: rgba(#000, 0.1);
    }
  }

  .more-menu-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    background-color: rgba(#000, 0.75);

    transform: translateY(100%);

    transition: transform 1ms 399ms, opacity 399ms;

    opacity: 0;

    &.show {
      transform: translateY(0);

      transition: transform 1ms, opacity 399ms 1ms;

      opacity: 1;
    }
  }
</style>
